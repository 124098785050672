import axios from 'axios'
import { blob } from 'stream/consumers';

/**
 * TODO - Move to FE library
 * Downloads file from url
 * @param ulr file url
 * @param filename file name with extension e.g 'file.pdf'
 */

export async function downloadFile(url: string, filename: string) {
   const response = await axios.get(url, {
      responseType: 'blob'
   })
   const blob = new Blob([response.data]);
   const fileURL = window.URL.createObjectURL(blob);

   // Setting various property values
   let alink = document.createElement('a');
   alink.href = fileURL;
   alink.download = filename;
   alink.click();
}
