import * as React from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import {
	ClassNameType,
	DisabledType,
	ErrorFieldType,
	NameType,
	OnChangeType,
	OptionsType,
	PlaceholderType,
	ReadonlyType,
	RequiredType,
	StyleType,
	ValueType,
} from '../../node_modules/carrier-fe/src/components/Forms/Inputs/Types';

export interface SearchableSelectInputProps {
	value: ValueType;
	onChange?: OnChangeType;
	onClear?: () => void;
	options?: OptionsType;
	name: NameType;
	required?: RequiredType;
	errorField?: ErrorFieldType;
	disabled?: DisabledType;
	readonly?: ReadonlyType;
	placeholder?: PlaceholderType;
	className?: ClassNameType;
	style?: StyleType;
	attributes?: any; // TODO - Make This Strict
	noOptionsMessage?: (inputValue: string) => React.ReactNode; // Added for custom "no options" handling
	loading?: boolean;
	clearable?: boolean;
	label?: string;
	noMargin?:boolean
}

export const SearchableSelectInput = (props: SearchableSelectInputProps) => {
	const {
		value,
		options = undefined,
		onChange = () => {},
		name,
		required = false,
		errorField = false,
		disabled = false,
		placeholder = undefined,
		className = undefined,
		style = {},
		attributes = undefined,
		noOptionsMessage = (inputValue) =>
			`No results found for "${inputValue}"`,
		loading = false,
		clearable = true,
		noMargin = false,
		label,
		onClear = () => {},
	} = props;

	if (!options) {
		return null;
	}

	const constructClasses = () => {
		let classes = ['form-control', 'p-0'];

		// Determine if the field should be highlighted for an error.
		if (errorField) {
			classes.push('is-invalid pe-4');
		} else if(!noMargin) {
			classes.push('mb-4');
		}

		if (!!className && Array.isArray(className)) {
			classes = [...classes, ...className];
		} else if (!!className && typeof className === 'string') {
			classes = [...classes, className];
		}

		return classNames(classes);
	};

	const extractValue = () => {
		if (!value || value === '' || !options || options.length <= 0) {
			return '';
		}

		for (let i = 0; i < options.length; i++) {
			if (options[i].value === value) {
				return options[i];
			}
		}

		return '';
	};

	// Shows when there is no results
	const CustomNoOptionsMessage = (props: any) => {
		const inputValue = props?.selectProps?.inputValue || '';
		return (
			<components.NoOptionsMessage {...props}>
				{noOptionsMessage(inputValue)}
			</components.NoOptionsMessage>
		);
	};

	const CustomValueContainer = (props: any) => {
		return (
			<div className="d-flex flex-column">
				<label className=" pt-1 text-secondary" style={{fontSize:13.5,paddingLeft:10}}>{label}</label>
				<components.ValueContainer {...props} className='pt-0' isDisabled={disabled}>
					{props.children}
				</components.ValueContainer>
			</div>
		);
	};

	return (
		<Select
			id={name}
			name={name}
			className={constructClasses()}
			required={required}
			defaultValue={extractValue()}
			value={extractValue()}
			onChange={(item: any,triggerAction) => {
				if(triggerAction?.action === 'clear') {
					onClear()
				}
				onChange(item ? item.value : null)
			}} // Removing this causes issues when clearing input
			isDisabled={disabled}
			menuPlacement={'bottom'}
			options={options}
			isSearchable={true}
			maxMenuHeight="150px"
			styles={{
				container: (baseStyles) => ({
					...baseStyles,
					...style,
                    backgroundColor: disabled ? '#E9ECEF' : 'white',
				}),
				control: (base) => ({
					...base,
					border: 'none',
					background: 'transparent',
					outline: 'none',
					boxShadow: 'none',
				}),
                singleValue: (base) => ({
                    ...base,
                    marginTop: -10,
                    paddingLeft: .5,
                    color: disabled ? 'black' : 'black',
                }),
				menuPortal: (baseStyles) => ({
					...baseStyles,
					zIndex: 9999,
				}),
				menuList: (baseStyles) => ({
					...baseStyles,
					zIndex: 9999,
				}),
				menu: (baseStyles) => ({
					...baseStyles,
					zIndex: 9999,
				}),
			}}
			closeMenuOnSelect={true}
			captureMenuScroll={true}
			closeMenuOnScroll={true}
			isClearable={clearable}
			isLoading={loading}
			components={{
				NoOptionsMessage: CustomNoOptionsMessage,
				ValueContainer: CustomValueContainer,
                IndicatorSeparator: () => null
			}}
			noOptionsMessage={(props) => <CustomNoOptionsMessage {...props} />}
			{...attributes}
		/>
	);
};

export default SearchableSelectInput;
