import {
    API_ENDPOINTS,
    DataTable,
    formatDate,
    FormInput,
    Icon,
    Icons,
    ImageType,
    PageLayout,
    replaceKeyWithValue,
    route,
    StringHelpers,
    useAuthorisationContext,
    useLangContext,
    getThemeFromUrl,
    Themes,
    Permissions,
    Variants,
} from 'carrier-fe';
import { useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CustomerCompanyItem } from '../../types/customer';

function CustomerPage() {
    const [refreshOnChange] = useState(new Date().toISOString());

    const navigate                = useNavigate();
    const [searchParams]            = useSearchParams();
    const { crud, models, fields }  = useLangContext();
    const { hasPermissionAccess }   = useAuthorisationContext();

    const title = useMemo(
        () =>
            StringHelpers.title(
                replaceKeyWithValue(
                    crud?.pages?.index?.title || ':model List ',
                    'model',
                    crud?.models?.customer || 'Customer'
                )
            ),
        [crud, models]
    );

  const handleOptionSelect = (
    action: string | ImageType | null,
    data: CustomerCompanyItem
  ) => {
    const { id } = data;

    const actions: { [key: string]: () => void } = {
      view: () => navigate(`/customers/${id}`),
    };

        if (typeof action === 'string' && actions[action]) {
            actions[action]();
        }
    };

    const items = useMemo(() => {
        if (!hasPermissionAccess(Permissions.CUSTOMER_STORE)) return [];

        return [
            {
                label: StringHelpers.title(
                    crud?.buttons?.create?.default || 'Create'
                ),
                disabled: false,
                onClick: () => navigate('/customers/create'),
                icon: <Icon icon={Icons.ADD} />,
            },
        ];
    }, []);

    const colMapping = (data: CustomerCompanyItem) => {
        return [
            {
                id: 'contact',
                label: StringHelpers.title(models?.contact || 'Contact'),
                value: StringHelpers.title(data?.first_name + ' ' + data?.last_name || '-'),
                sortable: true,
            },
            {
                id: 'name',
                label: StringHelpers.title(fields?.company || 'Company'),
                value: StringHelpers.title(data?.name || '-'),
                sortable: true,
            },
            {
                id: 'national_account',
                label: StringHelpers.title(
                    crud?.models?.national_account || 'National Account'
                ),
                value: data.type === 'national_account' ? 'Y' : 'N',
                sortable: true,
                visibleCondition: () => getThemeFromUrl() === Themes.Toshiba,
            },
            {
                id: 'created_at',
                label: StringHelpers.title(fields?.created_at || 'Created'),
                value: formatDate(data.created_at) || '-',
                sortable: true,
            },
            {
                id: 'deleted_at',
                label: StringHelpers.title(fields?.archived_at || 'Archived'),
                value: formatDate(data.deleted_at) || '-',
                sortable: true,
                visibleCondition: () => !!searchParams.get('filter[trashed]'),
            },
            {
                id: 'actions',
                label: StringHelpers.title(fields?.actions || 'Actions'),
                value: data.permissions.view ? (
                    <FormInput
                        type="select"
                        label={StringHelpers.title(fields?.actions || 'Action')}
                        placeholder={StringHelpers.title(fields?.select || 'Select')}
                        name="actions"
                        value=""
                        onChange={(value) => {
                            if (!!value && typeof value === 'string') {
                                handleOptionSelect(value, data);
                            }
                        }}
                        options={[
                            {
                                label: StringHelpers.title(
                                    crud?.view || 'View'
                                ),
                                value: 'view',
                            },
                        ]}
                        noMargin
                    />
                ) : null,
            },
        ];
    };

    return (
        <PageLayout title={title || 'Customer(s) List'}>
            <DataTable<CustomerCompanyItem>
                routeEndpoint={route(API_ENDPOINTS.REGISTRATION.CUSTOMER.INDEX)}
                colMapping={colMapping}
                items={items}
                incrementPaginationButtons
                refreshOnChange={refreshOnChange}
                canViewArchived
                variant={Variants.Info}
            />
        </PageLayout>
    );
}

export default CustomerPage;
