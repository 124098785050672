import axios from 'axios';
import {
	API_ENDPOINTS,
	Card,
	CardBody,
	customToast,
	formatDate,
	PageLayout,
	route,
	StringHelpers,
	useLangContext,
	useStore,
	Variants,
	Icons,
	useDisabledContext,
	Button,
	ButtonTypes,
	Dropdown,
	SubTitle,
	replaceKeyWithValue,
	replaceKeysWithValues,
	WidthConstrainedContainer,
	Spinner,
	DateFormats,
	CardData,
} from 'carrier-fe';
import { useParams, useNavigate } from 'react-router-dom';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';
import { InstallationType } from '../../../types/installationType';
import { useCallback, useEffect, useState } from 'react';
import { downloadFile } from '../../../util/FileHelpers';
import { SYSTEM_TYPES } from '../../../util/Constants';
import { STATUS_TYPES } from '../../../util/Constants';
import ConfirmationModal from '../../../components/EmailConfirmationModal';
import ArchiveCancelModal from '../../../components/ArchiveCancelModal';
import { format } from 'date-fns';
import { Appliance, InstallationResponse } from '../../../types/Installation';

interface LangPage {
	page: PageType;
	generic: LangGeneric;
}
interface LangGeneric {
	subcontractor: string;
	appliance: string;
	warranty_certificate: string;
	resend_warranty_certificate: string;
	resend_warranty_email: string;
	resend_warranty_prompt: string;
	resend_gassafe_email: string;
	download_gassafe_certificate: string;
}

interface PageType {
	installation_submitted_date: string;
	subcontracted: string;
	resend_gassafe_dialog_text: string;
	gassafe_downloaded: string;
	warranty_downloaded: string;
	servicing_needed: string;
	system_details: string;
	appliance_x_of_y: any;
}

function Installation() {
	const { installationId } = useParams();

	const [emailModalState, setEmailModalState] = useState({
		isOpen: false,
		type: '',
		isSending: false,
		email: '',
	});

	const [actionModalState, setActionModalState] = useState<{
		isOpen: boolean;
		type: 'archive' | 'cancel' | null;
	}>({
		isOpen: false,
		type: null,
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [pageLang, setPageLang] = useState<LangPage | null>(null);

	const { store, setValues } = useStore<InstallationType>(
		{} as InstallationType
	);
	const { crud, fields, getLangPage } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchPage = async () => {
			try {
				const data = await getLangPage(
					'registration/viessmann-installation/view'
				);
				setPageLang(data);
			} catch (err: any) {
				customToast({
					title: err?.response?.data?.message || '',
					variant: Variants.Danger,
				});
			}
		};

		fetchPage();
		fetchInstallationData();
	}, []);

	const fetchInstallationData = useCallback(async () => {
		setIsLoading(true);
		try {
			const { data } = await axios.get<{ data: InstallationType }>(
				route(API_ENDPOINTS.REGISTRATION.INSTALLATION.VIEW, {
					installationId: String(installationId),
				})
			);

			setValues(data.data);
		} catch (err: any) {
			customToast({
				title: err?.response?.data?.message || '',
				variant: Variants.Danger,
			});
		} finally {
			setIsLoading(false);
		}
	}, [installationId]);

	const openEmailModal = (
		type: 'gasSafe' | 'warranty' | 'warrantySelection'
	) => {
		setEmailModalState({
			isOpen: true,
			type,
			isSending: false,
			email: '',
		});
	};

	const closeEmailModal = () => {
		setEmailModalState({
			isOpen: false,
			type: '',
			isSending: false,
			email: '',
		});
	};

	const openActionModal = (type: 'archive' | 'cancel') => {
		setActionModalState({
			isOpen: true,
			type,
		});
	};

	const closeActionModal = () => {
		setActionModalState({
			isOpen: false,
			type: null,
		});
	};

	const downloadGasSafeCertificate = async () => {
		try {
			await downloadFile(
				route(
					API_ENDPOINTS.REGISTRATION.INSTALLATION
						.GAS_SAFE_CERTIFICATE,
					{
						installationId: installationId || '',
					}
				),
				'gasSafeCertificate.pdf'
			);
			customToast({
				title:
					pageLang?.page?.gassafe_downloaded ||
					'Gas Safe Certificate Downloaded Successfully',
				variant: Variants.Success,
			});
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message || '',
				variant: Variants.Danger,
			});
		}
	};

	const downloadWarrantyCertificate = async () => {
		try {
			await downloadFile(
				route(
					API_ENDPOINTS.REGISTRATION.INSTALLATION
						.WARRANTY_CERTIFICATE,
					{
						installationId: installationId || '',
					}
				),
				'warrantyCertificate.pdf'
			);
			customToast({
				title:
					pageLang?.page?.warranty_downloaded ||
					'Warranty Certificate Downloaded Successfully',
				variant: Variants.Success,
			});
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message || '',
				variant: Variants.Danger,
			});
		}
	};

	const getActions = (): DropdownItemProps[] => {
		if (!store || !store.permissions) return [];

		let actions: DropdownItemProps[] = [];

		if (store?.permissions?.update) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons?.edit?.default || 'Update'
				),
				onClick: () =>
					navigate(
						`/installation/${installationId}/edit?step=${
							store?.last_submitted_step
								? store?.last_submitted_step + 1
								: 1
						}`
					),
				variant: Variants.Dark,
				icon: Icons.EDIT,
				disabled: disabled,
			});
		}

		if (store?.permissions.cancel) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.cancel.default || 'Cancel'
				),
				onClick: () => openActionModal('cancel'),
				variant: Variants.Dark,
				icon: Icons.CANCEL,
				disabled: disabled,
			});
		}

		if (store?.permissions.delete) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.archive.default || 'Delete'
				),
				onClick: () => openActionModal('archive'),
				variant: Variants.Danger,
				icon: Icons.DELETE,
				disabled: disabled,
			});
		}

		if (
			store?.permissions.view &&
			store?.status === STATUS_TYPES.COMPLETED &&
			store?.system_type === SYSTEM_TYPES.GAS_BOILER &&
			store?.pushed_to_gassafe
		) {
			actions.push({
				label: StringHelpers.title(
					pageLang?.generic?.download_gassafe_certificate ||
						'Download GasSafe Certificate'
				),
				onClick: downloadGasSafeCertificate,
				variant: Variants.Dark,
				icon: Icons.DOWNLOAD,
				disabled: disabled,
			});
		}

		if (
			store?.permissions.view &&
			store?.status === STATUS_TYPES.COMPLETED &&
			store?.system_type === SYSTEM_TYPES.GAS_BOILER &&
			store?.installer_company?.customer_emails_enabled &&
			store?.pushed_to_gassafe
		) {
			actions.push({
				label: StringHelpers.title(
					pageLang?.generic?.resend_gassafe_email ||
						'Re-send GasSafe email'
				),
				onClick: () => openEmailModal('gasSafe'),
				variant: Variants.Dark,
				icon: Icons.MAIL_SEND,
				disabled: disabled,
			});
		}

		if (
			store?.permissions.view &&
			store?.installer_company?.customer_emails_enabled &&
			store?.status === STATUS_TYPES.COMPLETED
		) {
			actions.push({
				label: StringHelpers.title(
					pageLang?.generic.resend_warranty_prompt ||
						'Re-send Warranty prompt'
				),
				onClick: () => openEmailModal('warrantySelection'),
				variant: Variants.Dark,
				icon: Icons.MAIL_SEND,
				disabled: disabled,
			});
		}

		if (store?.permissions.view) {
			actions.push({
				label: StringHelpers.title(
					pageLang?.generic.warranty_certificate ||
						'Warranty Certificate'
				),
				onClick: downloadWarrantyCertificate,
				variant: Variants.Dark,
				icon: Icons.DOWNLOAD,
				disabled: disabled,
			});
		}

		if (
			store?.permissions.view &&
			store?.installer_company?.customer_emails_enabled
		) {
			actions.push({
				label: StringHelpers.title(
					pageLang?.generic?.resend_warranty_email ||
						'Re-send Warranty email'
				),
				// onClick: () => {
				//     customToast({
				//         title: 'Not Implemented Yet',
				//         variant: Variants.Warning
				//     })
				// },
				onClick: () => openEmailModal('warranty'),
				variant: Variants.Dark,
				icon: Icons.MAIL_SEND,
				disabled: disabled,
			});
		}

		return actions;
	};

	let title = replaceKeyWithValue(
		crud?.pages?.view.title || 'View :model',
		'model',
		crud?.models?.installation + ' ' + crud?.sub_titles?.details ||
			'Installation Details'
	);

	let actions = getActions();

	return (
		<PageLayout title={StringHelpers.title(title || 'View Installation')}>
			<WidthConstrainedContainer>
				{isLoading ? (
					<div className="d-flex justify-content-center align-items-center vh-100">
						<Spinner />
					</div>
				) : (
					<>
						<div className="mb-4 d-flex justify-content-between align-items-center">
							<Button
								label={StringHelpers.title(
									crud?.buttons?.back.default || 'Back'
								)}
								onClick={() => navigate('/installation')}
								variant={Variants.Dark}
								type={ButtonTypes.Outline}
								disabled={disabled}
							/>
							{actions.length > 0 && (
								<Dropdown
									label={StringHelpers.title(
										crud?.buttons?.actions.default ||
											'Actions'
									)}
									items={getActions()}
									type={ButtonTypes.Solid}
									variant={Variants.Dark}
									disabled={disabled}
								/>
							)}
						</div>

						<Card>
							<CardBody>
								<SubTitle
									className="mb-3 w-100"
									style={{ color: '#464C5E' }}
									title={StringHelpers.title(
										crud?.models?.installation +
											' ' +
											crud?.sub_titles?.details ||
											'Installation Details'
									)}
								/>
								<div className="row mb-4">
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.installer_company ||
														'Installation company'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.installer_company
														?.name ?? '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.completion_date ||
														'Work completion date'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													formatDate(
														store?.completion_date
													) || '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													pageLang?.page
														?.installation_submitted_date ||
														'Installation submitted date'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													formatDate(
														store?.created_at
													) ?? '-'
												)}
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.installer_individual ||
														'Installation Engineer'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.installer_individual
														?.name ?? '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.created_by_individual ||
														'Registered by'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.created_by_individual
														?.name || '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													pageLang?.page
														?.subcontracted ||
														'Subcontracted'
												)}
											</strong>
											<div>
												{store?.subcontractor_company_name
													? crud?.options?.boolean[0]
															.label || 'Yes'
													: crud?.options?.boolean[1]
															.label || 'No'}
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.status || 'Status'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.status_display || '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.created_at ||
														'created'
												)}
											</strong>
											<div>
												{store?.created_at
													? formatDate(
															store?.created_at
													  )
													: '-'}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.updated_at ||
														'updated'
												)}
											</strong>
											<div>
												{store?.updated_at
													? formatDate(
															store?.updated_at
													  )
													: '-'}
											</div>
										</div>
										{!!store?.deleted_at && (
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.archived_at ||
															'archived'
													)}
												</strong>
												<div>
													{store?.deleted_at
														? formatDate(
																store.deleted_at
														  )
														: '-'}
												</div>
											</div>
										)}
									</div>
								</div>
							</CardBody>
						</Card>

						{store?.subcontractor_company_name && (
							<Card>
								<CardBody>
									<SubTitle
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
										title={StringHelpers.title(
											crud?.sub_titles
												?.subcontractor_details ||
												'Subcontractor Details'
										)}
									/>
									<div className="row mb-4">
										<div className="col-12 col-lg-6 col-xxl-4">
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.subcontractor_company_name ||
															'Company Name'
													)}
												</strong>
												<div>
													{store?.subcontractor_company_name ??
														'-'}
												</div>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-xxl-4">
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.gas_safe_number ||
															'Gas Safe Number'
													)}
												</strong>
												<div>
													{store?.installer_company
														.gas_safe_number ?? '-'}
												</div>
											</div>
										</div>
									</div>

									<SubTitle
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
										title={StringHelpers.title(
											crud?.sub_titles?.address ||
												'Address'
										)}
									/>
									<div className="row mb-4">
										<div className="col-12 col-lg-6 col-xxl-4">
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.address_line_1 ||
															'Address line 1'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store
															?.subcontractor_address
															?.address_line_1 ||
															'-'
													)}
												</div>
											</div>
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.address_line_2 ||
															'Address line 2'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store
															?.subcontractor_address
															?.address_line_2 ||
															'-'
													)}
												</div>
											</div>
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.address_line_3 ||
															'Address line 3'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store
															?.subcontractor_address
															?.address_line_3 ||
															'-'
													)}
												</div>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-xxl-4">
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.town_city ||
															'Town / City'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store
															?.subcontractor_address
															?.town_city || '-'
													)}
												</div>
											</div>
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.state_county ||
															'State / County'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store
															?.subcontractor_address
															?.state_county ||
															'-'
													)}
												</div>
											</div>
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.postcode_zipcode ||
															'Postcode / Zipcode'
													)}
												</strong>
												<div>
													{store?.subcontractor_address?.postcode_zipcode?.toUpperCase() ||
														'-'}
												</div>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-xxl-4">
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.country ||
															'Country'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store
															?.subcontractor_address
															?.country || '-'
													)}
												</div>
											</div>
										</div>
									</div>

									<SubTitle
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
										title={StringHelpers.title(
											crud?.sub_titles?.engineer ||
												'Engineer'
										)}
									/>
									<div className="row mb-4">
										<div className="col-12 col-lg-6 col-xxl-4">
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.name || 'Name'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store?.subcontractor_engineer_name ||
															'-'
													)}
												</div>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-xxl-4">
											<div className="d-flex flex-column mb-3">
												<strong>
													{StringHelpers.title(
														fields?.gas_safe_license ||
															'Gas Safe Licence'
													)}
												</strong>
												<div>
													{StringHelpers.title(
														store?.subcontractor_engineer_gas_safe_licence_card_number ||
															'-'
													)}
												</div>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						)}

						<Card>
							<CardBody>
								<SubTitle
									className="mb-3 w-100"
									style={{ color: '#464C5E' }}
									title={StringHelpers.title(
										crud?.models?.customer +
											' ' +
											crud?.sub_titles?.details ||
											'Customer Details'
									)}
								/>
								<div className="row mb-4">
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.name || 'Name'
												)}
											</strong>
											<div>
												{store?.customer_company
													?.primary_individual
													?.permissions?.view ? (
													<a
														href={`/customers/${store?.customer_company?.id}`}
														target="_blank"
														className="text-decoration-none"
														rel="noreferrer"
													>
														{StringHelpers.title(
															store
																?.customer_company
																?.primary_individual
																?.name || '-'
														)}
													</a>
												) : (
													<span>
														{StringHelpers.title(
															store
																?.customer_company
																?.primary_individual
																?.name || '-'
														)}
													</span>
												)}
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.company_name ||
														'Company Name'
												)}
											</strong>
											<div>
												{store?.customer_company
													?.name ?? '-'}
											</div>
										</div>
									</div>
								</div>

								<SubTitle
									className="mb-3 w-100"
									style={{ color: '#464C5E' }}
									title={StringHelpers.title(
										crud?.sub_titles?.address || 'Address'
									)}
								/>
								<div className="row mb-4">
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.address_line_1 ||
														'Address line 1'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.customer_company
														?.primary_site?.address
														?.address_line_1 ?? '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.address_line_2 ||
														'Address line 2'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.customer_company
														?.primary_site?.address
														?.address_line_2 ?? '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.address_line_3 ||
														'Address line 3'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.customer_company
														?.primary_site?.address
														?.address_line_3 ?? '-'
												)}
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.town_city ||
														'Town / City'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.customer_company
														?.primary_site?.address
														?.town_city ?? '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.state_county ||
														'State / County'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.customer_company
														?.primary_site?.address
														?.state_county ?? '-'
												)}
											</div>
										</div>
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.postcode_zipcode ||
														'Postcode / Zipcode'
												)}
											</strong>
											<div>
												{store?.customer_company?.primary_site?.address?.postcode_zipcode?.toUpperCase() ??
													'-'}
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.country || 'Country'
												)}
											</strong>
											<div>
												{StringHelpers.title(
													store?.customer_company
														?.primary_site?.address
														?.country ?? '-'
												)}
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-6 col-xxl-4">
										<div className="d-flex flex-column mb-3">
											<strong>
												{StringHelpers.title(
													fields?.location_new_build ||
														'New build'
												)}
											</strong>
											<div>
												{store?.location_new_build
													? crud?.options?.boolean[0]
															.label || 'Yes'
													: crud?.options?.boolean[1]
															.label || 'No'}
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>

						<Card>
							<CardBody>
								<SubTitle
									className="mb-3 w-100"
									style={{ color: '#464C5E' }}
									title={StringHelpers.title(
										pageLang?.page?.system_details ||
											'Appliance Details'
									)}
								/>
								<div>
									<div className=" mb-4">
										<strong>
											{StringHelpers.title(
												fields?.system_type ||
													'System type'
											)}
										</strong>
										<div>
											{StringHelpers.title(
												store?.system_type_display ||
													'-'
											)}
										</div>
									</div>
								</div>
								{store.appliances?.map(
									(appliance: Appliance, index: number) => (
										<Card key={index}>
											<CardBody>
												<div className="mb-3">
													<strong>
														{replaceKeysWithValues(
															pageLang?.page
																?.appliance_x_of_y ||
																'',
															{
																nth: index + 1,
																total: store
																	?.appliances
																	?.length,
															}
														)}
													</strong>
												</div>
												<div className="row">
													<CardData.Column>
														<CardData.ColumnItem
															title={StringHelpers.title(
																fields?.serial_number ||
																	'Serial Number'
															)}
															value={
																appliance?.serial_number
															}
														/>
														<CardData.ColumnItem
															title={StringHelpers.title(
																fields?.name ||
																	'Name'
															)}
															value={
																appliance
																	?.appliance_details
																	?.full_name
															}
														/>
													</CardData.Column>
													<CardData.Column>
														<CardData.ColumnItem
															title={StringHelpers.title(
																fields?.appliance_details_model ||
																	'Model'
															)}
															value={
																appliance
																	?.appliance_details
																	?.model
															}
														/>
														<CardData.ColumnItem
															title={StringHelpers.title(
																fields?.appliance_details_type ||
																	'Type'
															)}
															value={
																appliance
																	?.appliance_details
																	?.appliance_type
															}
														/>
													</CardData.Column>
													<CardData.Column>
														<CardData.ColumnItem
															title={StringHelpers.title(
																fields?.appliance_details_version ||
																	'Version'
															)}
															value={
																appliance
																	?.appliance_details
																	?.version
															}
														/>
														<CardData.ColumnItem
															title={StringHelpers.title(
																fields?.appliance_details_installation_type ||
																	'Installation type'
															)}
															value={
																appliance
																	?.appliance_details
																	?.installation_type
															}
														/>
													</CardData.Column>
												</div>
											</CardBody>
										</Card>
									)
								)}
							</CardBody>
						</Card>
					</>
				)}
			</WidthConstrainedContainer>

			<ArchiveCancelModal
				modalOpen={actionModalState.isOpen}
				modalClose={closeActionModal}
				title={StringHelpers.title(
					replaceKeyWithValue(
						actionModalState.type === 'archive'
							? crud?.modals?.archive.title || 'Archive :name?'
							: crud?.modals?.cancel?.title ||
									'Cancel Installation?',
						'name',
						crud?.models?.installation || 'Installation'
					)
				)}
				type={actionModalState.type || 'cancel'}
				onSuccess={fetchInstallationData}
				installationId={installationId || ''}
				storeData={store}
			/>

			<ConfirmationModal
				modalOpen={emailModalState.isOpen}
				modalClose={closeEmailModal}
				title={
					emailModalState.type === 'gasSafe'
						? pageLang?.generic?.resend_gassafe_email ||
						  'Resend Gas Safe Email'
						: emailModalState.type === 'warranty'
						? 'Resend Warranty Email'
						: 'Select Warranty'
				}
				type={
					emailModalState.type as
						| 'gasSafe'
						| 'warranty'
						| 'warrantySelection'
				}
				dynamicText={
					emailModalState.type === 'gasSafe'
						? StringHelpers.replace(
								pageLang?.page?.resend_gassafe_dialog_text ||
									'',
								'\\',
								''
						  ) ||
						  "You're about to resend the GasSafe letter & certificate to the customer as an e-mail."
						: emailModalState.type === 'warranty'
						? StringHelpers.title(
								"You're about to resend the Warranty Certificate to the customer as an e-mail."
						  )
						: StringHelpers.title(
								"You're about to send a warranty selection prompt to the customer."
						  )
				}
				defaultEmail={
					store?.customer_company?.primary_individual?.email || ''
				}
				onSuccess={fetchInstallationData}
				storeData={store}
			/>
		</PageLayout>
	);
}

export default Installation;
