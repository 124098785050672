import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	Icon,
	Icons,
	ImageType,
	PageLayout,
	route,
	useLangContext,
	replaceKeyWithValue,
	StringHelpers,
	useAuthorisationContext,
	useUserContext,
	Roles,
	DateFormats,
	Variants,
} from 'carrier-fe';
import { useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { InstallationType } from '../../types/installationType';
import { format } from 'date-fns';

function InstallationList() {
	const [refreshOnChange, setRefreshOnChange] = useState(
		new Date().toISOString()
	);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const { crud, fields, models } = useLangContext();
	const { hasPolicyAccess } = useAuthorisationContext();
	const { user } = useUserContext();

	const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.title || ':model List ',
					'model',
					crud?.models?.installation || 'Installation'
				)
			),
		[crud, models]
	);

	const handleOptionSelect = (
		action: string | ImageType | null,
		data: InstallationType
	) => {
		const { id = '' } = data;

		const actions: { [key: string]: () => void } = {
			view: () => navigate(`/installation/${id}`),
		};

		if (typeof action === 'string' && actions[action]) {
			actions[action]();
		}
	};

	const items = useMemo(() => {
		if (!hasPolicyAccess({ name: 'viessmann_installation', method: 'create' })) return [];

		return [
			{
				label: StringHelpers.title(
					crud.buttons.create.default || 'Create'
				),
				disabled: false,
				onClick: () => navigate('/installation/create'),
				icon: <Icon icon={Icons.ADD} />,
			},
		];
	}, []);

	const colMapping = (data: InstallationType, user: any) => {

		const cols: any = [
			{
				id: 'company_name',
				label: StringHelpers.title(fields?.installer_company || 'Installation Company'),
				value: data?.name || '-',
				sortable: true,
				visibleCondition: () => user?.role_name === Roles.PLATFORM_MANAGER,

			},
			{
				id: 'engineer_name',
				label: StringHelpers.title('Engineer'),
				value: data?.first_name + ' ' + data?.last_name || '-',
				sortable: true,
			},
			{
				id: 'completion_date',
				label: StringHelpers.title(fields?.date || 'Date'),
				value: format(new Date(data.completion_date),DateFormats.DATE) || '-',
				sortable: true,
			},
			{
				id: 'status',
				label: StringHelpers.title(fields?.status || 'status'),
				value: data?.status_display || '-',
				sortable: true,
			},
			{
				id: 'deleted_at',
				label: StringHelpers.title(fields?.archived || 'Archived'),
				value: formatDate(data.deleted_at || '-'),
				sortable: true,
				visibleCondition: () => !!searchParams.get('filter[trashed]'),
			},
			{
				id: 'actions',
				label: StringHelpers.title(fields?.actions || 'Actions'),
				value: data.permissions.view ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || 'Action')}
						placeholder={StringHelpers.title(fields?.select || 'Select')}
						name="actions"
						value=""
						onChange={(value: any) => {
							handleOptionSelect(value, data);
						}}
						options={[
							{
								label: StringHelpers.title(
									crud?.view || 'View'
								),
								value: 'view',
							},
						]}
						noMargin
					/>
				) : null,
			},
		];

		return cols;
	};


	return (
		<PageLayout title={title || 'Installation List'}>
			<DataTable<InstallationType>
				routeEndpoint={route(API_ENDPOINTS.REGISTRATION.INSTALLATION.INDEX)}
				colMapping={(data) => colMapping(data, user)}
				items={items}
				incrementPaginationButtons
				refreshOnChange={refreshOnChange}
				variant={Variants.Info}
			/>
		</PageLayout>
	);
}

export default InstallationList;
