import {
   API_ENDPOINTS,
   customToast,
   replaceKeyWithValue,
   route,
   SelectionCardsGroup,
   SelectOption,
   Stepper,
   StringHelpers,
   useLangContext,
   useQueries,
   Variants,
} from 'carrier-fe';
import ViessmannGas from 'carrier-fe/src/images/viessmann_gas.jpg';
import ViessmannHeatpumps from 'carrier-fe/src/images/viessmann_heatpumps.jpg';
import ViessmannOther from 'carrier-fe/src/images/viessmann_other.png';
import ViessmannSolar from 'carrier-fe/src/images/viessmann_solar.jpg';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { InstallationResponse } from '../../types/Installation';
import { StepProps } from '../../types/Journey';
import { SYSTEM_TYPES } from '../../util/Constants';
import axios from 'axios';

function Step2({ form, loading, stepLang }: StepProps) {
   const params = useParams();
   const { crud, fields } = useLangContext();
   const [searchParams] = useSearchParams();
   const installationId = params?.installationId || searchParams.get('installationId');
   const [fetchingDropdowns, setFetchingDropdowns] = useState(false);
   const { store } = form;

   const [selectOptions, setSelectOptions] = useState<{ [key: string]: SelectOption[] }>({
      system_types: [],
   });

   const { data, error, isLoading } = useQueries([
      {
         key: 'installation',
         config: {
            method: 'GET',
            url: route(API_ENDPOINTS.REGISTRATION.INSTALLATION.VIEW, {
               installationId: installationId ?? '',
            }),
         },
      },
   ]);

   const setDefaultSystemType = useCallback((options: SelectOption[]) => {
      const enabledOptions = options.filter((option) => !option.disabled);
      if (enabledOptions.length === 1) {
         form?.setValues({ system_type: enabledOptions[0].value });
      }
   }, [form]);

   const updateSystemTypeOptions = useCallback(
      (installation: InstallationResponse, systemTypes: SelectOption[]) => {
         return systemTypes
            ?.map((option) => {
               const { installer_company: installer } = installation || {};

               switch (option.value) {
                  case SYSTEM_TYPES.HEAT_PUMPS:
                     return {
                        ...option,
                        disabled: !installer?.heat_pump_systems_enabled,
                        imageSrc: ViessmannHeatpumps,
                     };
                  case SYSTEM_TYPES.GAS_BOILER:
                     return {
                        ...option,
                        disabled: !installer?.gas_boiler_systems_enabled,
                        imageSrc: ViessmannGas,
                     };
                  case SYSTEM_TYPES.SOLAR:
                     return {
                        ...option,
                        disabled: !installer?.solar_systems_enabled,
                        imageSrc: ViessmannSolar,
                     };
                  case SYSTEM_TYPES.OTHER:
                     return {
                        ...option,
                        disabled: !installer?.other_systems_enabled,
                        imageSrc: ViessmannOther,
                     };
                  default:
                     return option;
               }
            })
            .filter((op) => !op.disabled);
      },
      []
   );

   useLayoutEffect(() => {
      if (error?.installation) {
         customToast({
            title: (error?.installation?.response?.data as any)?.message || '',
            variant: Variants.Danger,
         });
      } else if (data?.installation?.data) {
         const installation: InstallationResponse = data.installation.data;

         getAndSetSystemTypes(installation);
      }
   }, [data, error]);

   const getAndSetSystemTypes = useCallback(
      async (installation: InstallationResponse) => {
         setFetchingDropdowns(true);
         try {
            const response = await axios.get(
               route(
                  `${API_ENDPOINTS.GENERIC.SELECT.SYSTEM_TYPES}?installer_company_id=${
                     installation.installer_company_id
                  }&installer_user_id=${installation?.installer_user_id}`
               )
            );
            const updatedOptions = updateSystemTypeOptions(installation, response?.data?.data);
            setSelectOptions((prevOptions) => ({
               ...prevOptions,
               system_types: updatedOptions,
            }));
            setDefaultSystemType(updatedOptions);
         } catch (error: any) {
            customToast({
               title: error?.response?.data?.message || '',
               variant: Variants.Danger,
            });
         } finally {
            setFetchingDropdowns(false);
         }
      },
      [setDefaultSystemType, updateSystemTypeOptions]
   );

   const componenetLoading = useMemo(() => {
      return fetchingDropdowns || isLoading || loading;
   }, [fetchingDropdowns, isLoading, loading]);

   return (
      <Stepper.Step
         loading={componenetLoading}
         title={stepLang?.title}
      >
         <div>
            <SelectionCardsGroup
               options={selectOptions?.system_types as any}
               value={store?.system_type}
               onChange={(value) => {
                  form?.setValues({ system_type: value as any });
                  form?.setErrors({ system_type: undefined });
               }}
               errors={form?.errors?.system_type}
               label={
                  replaceKeyWithValue(
                     crud?.sub_titles?.select || 'Please select a :field',
                     'field',
                     fields?.system_type || 'System Type'
                  ) + ':'
               }
            />
         </div>
      </Stepper.Step>
   );
}

export default Step2;
