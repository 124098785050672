import React, { useLayoutEffect, useMemo, useState } from 'react';
import { StepProps } from '../../types/Journey';
import { InstallationFormData } from '../../pages/installation/journey';
import {
   API_ENDPOINTS,
   CheckboxInput,
   customToast,
   FormInput,
   route,
   SelectOption,
   StringHelpers,
   useLangContext,
   Variants,
   Stepper,
   useQueries,
   ImageType,
   SubTitle,
   SummaryLineItems,
} from 'carrier-fe';
import { QUERY_KEYS, SYSTEM_TYPES, TEN_MINUTES } from '../../util/Constants';

const cacheConfig = {
   enabled: true,
   config: {
      expires: TEN_MINUTES,
   },
};

function Step6({ form, stepLang }: StepProps) {
   const { crud, fields } = useLangContext();
   const [summaryData, setSummaryData] = useState(form?.store);

   // Getting dropdowns to show labels on fields instead of ids
   const { data, error, isLoading } = useQueries([
      {
         key: QUERY_KEYS.INSTALLER_COMPANIES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.SELECT.COMPANIES}?types[]=installer`),
         },
      },
      {
         key: QUERY_KEYS.INSTALLER_USERS,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.SELECT.USERS}?roles[]=engineer`),
         },
      },
      {
         key: QUERY_KEYS.CUSTOMERS,
         config: {
            method: 'GET',
            url: route(
               `${API_ENDPOINTS.GENERIC.SELECT.CUSTOMERS}?company_id=${form?.store?.installer_company_id}`
            ),
         },
      },
      {
         key: QUERY_KEYS.MERCHANTS,
         config: {
            method: 'GET',
            url: route(API_ENDPOINTS.GENERIC.SELECT.MERCHANTS),
         },
         cache: cacheConfig,
      },
   ]);

   const findOption = (value: string, options: SelectOption[]): SelectOption => {
      try {
         return (
            options?.find((option) => {
               return option.value === value;
            }) ?? { label: '-', value }
         );
      } catch (error) {
         return { label: '-', value };
      }
   };

   useLayoutEffect(() => {
      if (error) {
         Object.values(error).map((e) =>
            customToast({ title: (e?.response?.data as any)?.message, variant: Variants.Danger })
         );
      }
      if (data) {
         // Showing dropdown label instead of values for some
         const currentSummaryData = { ...form.store };
         if (data?.installer_companies) {
            currentSummaryData['installer_company_id'] = findOption(
               currentSummaryData['installer_company_id'],
               data?.installer_companies?.data
            )?.label;
         }
         if (data?.installer_users) {
            currentSummaryData['installer_user_id'] = findOption(
               currentSummaryData['installer_user_id'],
               data?.installer_users?.data
            )?.label;
         }
         if (data?.customer_companies) {
            currentSummaryData['customer_company_id'] = findOption(
               currentSummaryData['customer_company_id'],
               data?.customer_companies?.data
            )?.label;
         }
         if (data?.merchants) {
            currentSummaryData['appliances'] = currentSummaryData?.appliances?.map((appliance) => {
               return {
                  ...appliance,
                  merchant_id: findOption(appliance.merchant_id, data?.merchants?.data)?.label,
               };
            });
         }
         setSummaryData(currentSummaryData);
      }
   }, [data, error]);

   const summaryConf = useMemo(() => {
      return summaryConfig(summaryData, fields, crud);
   }, [crud, fields, summaryData]);

   return (
      <Stepper.Step loading={isLoading} title={stepLang?.title} description={stepLang?.description}>
         <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
               {summaryConf?.map((item, index) => (
                  <SummaryLineItems
                     maxPerView={3}
                     key={index}
                     title={item.title}
                     items={item.items}
                  />
               ))}
            </div>
            <div>
               <SubTitle
                  title={StringHelpers.title(crud?.sub_titles?.confirmation || 'Confirmation')}
                  className="mb-3 w-100"
                  style={{ color: '#464C5E' }}
               />
               <FormInput
                  name="confirm"
                  label={StringHelpers.sentence(
                     fields?.confirmed || fallbackFieldLabels.confirmed
                  )}
                  type="select"
                  required
                  value={form.store['confirmed']}
                  onChange={(value: string | boolean | string[] | ImageType | null) =>
                     form?.setValues({
                        confirmed: typeof value === 'string' ? JSON.parse(value) : value,
                     })
                  }
                  options={crud?.options?.boolean.map((option: SelectOption) => ({
                     ...option,
                     value: Number(option.value) === 1 ? true : false,
                  }))}
                  errorMessages={form.errors?.['confirmed']}
               />
            </div>
         </div>
      </Stepper.Step>
   );
}

export default Step6;

type SummaryItems = React.ComponentProps<typeof SummaryLineItems>['items'];
/**
 * BEHOLD THE SUMMONER
 * - Builds journey summary
 * - handles conditional summary items e.g. ...(data['subcontractor_exists']
 */
const summaryConfig = (
   data: InstallationFormData,
   langFields: any,
   crud: any
): { title: string; items: SummaryItems }[] => {
   const yesNoOptions = crud?.options?.boolean.reduce((acc: any, option: SelectOption) => {
      acc[option.value] = option.label;
      return acc;
   }, {});

   return [
      {
         title: `${StringHelpers.sentence(crud?.sub_titles?.step || 'Step')} 01`,
         items: [
            {
               label: StringHelpers.sentence(langFields?.installer_company_id || fallbackFieldLabels.installer_company_id),
               value: StringHelpers.sentence(data['installer_company_id'] || '-'),
            },
            {
               label: StringHelpers.sentence(langFields?.installer_user_id || fallbackFieldLabels.installer_user_id),
               value: StringHelpers.sentence(data['installer_user_id'] || '-'),
            },
         ],
      },
      {
         title: `${StringHelpers.sentence(crud?.sub_titles?.step || 'Step')} 02`,
         items: [
            {
               label: StringHelpers.sentence(langFields?.system_type || fallbackFieldLabels.system_type),
               value: StringHelpers.sentence(
                  StringHelpers.replace(data['system_type'] || '-', '_', ' ')
               ),
            },
         ],
      },
      {
         title: `${StringHelpers.sentence(crud?.sub_titles?.step || 'Step')} 03`,
         items: [
            {
               label: StringHelpers.sentence(langFields?.completion_date || fallbackFieldLabels.completion_date),
               value: StringHelpers.sentence(data['completion_date'] || '-'),
            },
            {
               label: StringHelpers.sentence(langFields?.subcontractor_exists || fallbackFieldLabels.subcontractor_exists),
               value: StringHelpers.sentence(yesNoOptions[data['subcontractor_exists'] ? 1 : 0]),
            },
            ...(data['subcontractor_exists']
               ? [
                    {
                       label: StringHelpers.sentence(
                          langFields?.subcontractor_company_name || fallbackFieldLabels.subcontractor_company_name
                       ),
                       value: StringHelpers.sentence(data['subcontractor_company_name'] || '-'),
                    },
                    ...(data?.system_type === SYSTEM_TYPES.GAS_BOILER
                       ? [
                            {
                               label: StringHelpers.sentence(
                                  langFields?.subcontractor_company_gas_safe_number ||
                                     fallbackFieldLabels.subcontractor_company_gas_safe_number
                               ),
                               value: StringHelpers.sentence(
                                  data['subcontractor_company_gas_safe_number'] || '-'
                               ),
                            },
                            {
                               label: StringHelpers.sentence(
                                  langFields?.subcontractor_company_gas_safe_number_confirmed ||
                                     fallbackFieldLabels.subcontractor_company_gas_safe_number_confirmed
                               ),
                               value: StringHelpers.sentence(
                                  yesNoOptions[data['subcontractor_company_gas_safe_number_confirmed'] ? 1 : 0]
                               ),
                            },
                         ]
                       : []),
                    {
                       label: StringHelpers.sentence(
                          langFields?.subcontractor_engineer_gas_safe_licence_card_number ||
                             fallbackFieldLabels.subcontractor_engineer_gas_safe_licence_card_number
                       ),
                       value: StringHelpers.sentence(
                          data['subcontractor_engineer_gas_safe_licence_card_number'] || '-'
                       ),
                    },
                    {
                       label: StringHelpers.sentence(
                          langFields?.subcontractor_engineer_gas_safe_licence_card_number_confirmed ||
                             fallbackFieldLabels.subcontractor_engineer_gas_safe_licence_card_number_confirmed
                       ),
                       value: StringHelpers.sentence(
                          yesNoOptions[data['subcontractor_engineer_gas_safe_licence_card_number_confirmed'] ? 1 : 0]
                       ),
                    },
                    {
                       label: StringHelpers.sentence(
                          langFields?.subcontractor_engineer_first_name || fallbackFieldLabels.subcontractor_engineer_first_name
                       ),
                       value: StringHelpers.sentence(data['subcontractor_engineer_first_name'] || '-'),
                    },
                    {
                       label: StringHelpers.sentence(
                          langFields?.subcontractor_engineer_last_name || fallbackFieldLabels.subcontractor_engineer_last_name
                       ),
                       value: StringHelpers.sentence(data['subcontractor_engineer_last_name'] || '-'),
                    },
                 ]
               : []),
         ],
      },

      {
         title: `${StringHelpers.sentence(crud?.sub_titles?.step || 'Step')} 04`,
         items: [
            {
               label: StringHelpers.sentence(langFields?.customer_company_id || fallbackFieldLabels.customer_company_id),
               value: StringHelpers.sentence(data['customer_company_id'] || '-'),
            },
            {
               label: StringHelpers.sentence(langFields?.customer_title || fallbackFieldLabels.customer_title),
               value: StringHelpers.sentence(data['customer_title'] || '-'),
            },
            {
               label: StringHelpers.sentence(
                  langFields?.customer_contact_type_for_gas_safe || fallbackFieldLabels.customer_contact_type_for_gas_safe
               ),
               value: StringHelpers.sentence(data['customer_contact_type_for_gas_safe'] || '-'),
            },
            {
               label: StringHelpers.sentence(
                  langFields?.customer_property_type_for_gas_safe || fallbackFieldLabels.customer_property_type_for_gas_safe
               ),
               value: StringHelpers.sentence(data['customer_property_type_for_gas_safe'] || '-'),
            },
            {
               label: StringHelpers.sentence(langFields?.job_reference || fallbackFieldLabels.job_reference),
               value: StringHelpers.sentence(data['job_reference'] || '-'),
            },
            {
               label: StringHelpers.sentence(
                  langFields?.customer_email_override_exists || fallbackFieldLabels.customer_email_override_exists
               ),
               value: StringHelpers.sentence(yesNoOptions[data['customer_email_override_exists'] ? 1 : 0]),
            },
            {
               label: StringHelpers.sentence(langFields?.customer_email_override || fallbackFieldLabels.customer_email_override),
               value: StringHelpers.sentence(data['customer_email_override'] || '-'),
               visible: data['customer_email_override_exists'],
            },
         ],
      },

      {
         title: `${StringHelpers.sentence(crud?.sub_titles?.step || 'Step')} 05`,
         items: data?.appliances?.map((item, index) => ({
            label: StringHelpers.sentence(langFields?.appliance || `Appliance ${index + 1}`),
            value: [
               {
                  label: StringHelpers.sentence(langFields?.serial_number || fallbackFieldLabels.serial_number),
                  value: StringHelpers.sentence(item?.serial_number || '-'),
               },
               ...(data?.system_type === SYSTEM_TYPES.GAS_BOILER
                  ? [
                       {
                          label: StringHelpers.sentence(
                             langFields?.gas_safe_product_location || fallbackFieldLabels.product_location
                          ),
                          value: StringHelpers.sentence(item?.gas_safe_product_location || '-'),
                       },
                       {
                          label: StringHelpers.sentence(
                             langFields?.gas_safe_work_category || fallbackFieldLabels.work_category
                          ),
                          value: StringHelpers.sentence(item?.gas_safe_work_category || '-'),
                       },
                    ]
                  : []),
               {
                  label: StringHelpers.sentence(langFields?.merchant_id || fallbackFieldLabels.merchant_id),
                  value: StringHelpers.sentence(item?.merchant_id || '-'),
               },
               item?.merchant_id?.toLowerCase() === 'other'
                  ? {
                       label: StringHelpers.sentence(
                          langFields?.other_merchant_name || fallbackFieldLabels.other_merchant_name
                       ),
                       value: StringHelpers.sentence(item?.other_merchant_name || '-'),
                    }
                  : null,
               {
                  label: StringHelpers.sentence(
                     langFields?.installer_will_service || fallbackFieldLabels.installer_will_service
                  ),
                  value: StringHelpers.sentence(yesNoOptions[item?.installer_will_service ? 1 : 0]),
               },
               !item?.installer_will_service
                  ? {
                       label: StringHelpers.sentence(
                          langFields?.servicing_details || fallbackFieldLabels.servicing_details
                       ),
                       value: StringHelpers.sentence(item?.servicing_details || '-'),
                    }
                  : null,
               !item?.installer_will_service
                  ? {
                       label: StringHelpers.sentence(
                          langFields?.viessmann_will_contact_customer_about_servicing ||
                             fallbackFieldLabels.viessmann_will_contact_customer_about_servicing
                       ),
                       value: StringHelpers.sentence(
                          yesNoOptions[item?.viessmann_will_contact_customer_about_servicing ? 1 : 0]
                       ),
                    }
                  : null,
            ].filter(Boolean) as SummaryItems, // Filter out null values
         })),
      },
   ];
};

const fallbackFieldLabels = {
   installer_company_id: 'installation company',
   installer_user_id: 'installation engineer',
   system_type: 'system type',
   completion_date: 'work completion date',
   subcontractor_exists: 'work was subcontracted',
   subcontractor_company_name: 'company name',
   subcontractor_company_gas_safe_number: 'company GasSafe number',
   subcontractor_company_gas_safe_number_confirmed: 'Can you confirm that the company\'s GasSafe details are correct?',
   subcontractor_engineer_gas_safe_licence_card_number: 'engineer GasSafe licence card number',
   subcontractor_engineer_gas_safe_licence_card_number_confirmed: 'Can you confirm that the engineer\'s GasSafe details are correct?',
   subcontractor_engineer_first_name: 'engineer first name',
   subcontractor_engineer_last_name: 'engineer last name',
   customer_company_id: 'customer',
   customer_title: 'title',
   customer_contact_type_for_gas_safe: 'customer type',
   customer_property_type_for_gas_safe: 'property type',
   job_reference: 'job reference',
   customer_email_override_exists: 'override customer email',
   customer_email_override: 'customer email',
   appliances: 'appliance details',
   merchant_id: 'merchant',
   serial_number: 'serial number',
   product_location: 'product location',
   other_merchant_name: 'merchant other',
   work_category: 'work category',
   installer_will_service: 'Do you intend to service and maintain this product?',
   servicing_details: 'please provide details',
   viessmann_will_contact_customer_about_servicing: 'Do you want Viessmann to contact you about servicing the product?',
   confirmed: 'Do you confirm that the details provided above are correct?',
};