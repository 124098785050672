import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Root from '../pages/root';
import Dashboard from '../pages/dashboard';
import { AuthenticatedLayout, Permissions, Platforms, ProtectedPage } from 'carrier-fe';
import CustomerPage from '../pages/customer';
import CustomerCompanyForm from '../pages/customer/form';
import CustomerCompanyView from '../pages/customer/view';
import Journey from '../pages/installation/journey';
import Installation from '../pages/installation/view';
import InstallationList from '../pages/installation';

function Router() {
   return (
      <BrowserRouter>
         <Routes>
            <Route index path="/" element={<Root />} />

            {/*
             * TODO - FOLLOWING ROUTES NEED TO BE AUTH PROTECTED
             *      - IS A TOKEN SET IN THE COOKIE?
             *      - IS THAT TOKEN VALID (CHECK VIA THE SERVER?
             *      - IF NOT -> LOGOUT AND REDIRECT TO LOGIN
             */}
            <Route path="/" element={<AuthenticatedLayout platform={Platforms.REGISTRATION} />}>
               <Route index path="/dashboard" element={<Dashboard />} />

               <Route path="/customers">
                  <Route index element={<CustomerPage />} />
                  <Route
                     path=":customerId"
                     element={
                        <ProtectedPage requiredPermissions={Permissions.CUSTOMER_SHOW}>
                           <CustomerCompanyView />
                        </ProtectedPage>
                     }
                  />
                  <Route
                     path="create"
                     element={
                        <ProtectedPage requiredPermissions={Permissions.CUSTOMER_STORE}>
                           <CustomerCompanyForm />
                        </ProtectedPage>
                     }
                  />
                  <Route
                     path=":customerId/edit"
                     element={
                        <ProtectedPage requiredPermissions={Permissions.CUSTOMER_UPDATE}>
                           <CustomerCompanyForm />
                        </ProtectedPage>
                     }
                  />
               </Route>

               <Route path="/installation">
                  <Route index element={<InstallationList />} />
                  <Route
                     path="create"
                     element={
                        <ProtectedPage
                           requiredPermissions={Permissions.VIESSMANN_INSTALLATION_STORE}
                        >
                           <Journey />
                        </ProtectedPage>
                     }
                  />
                  <Route path=":installationId/edit" element={<Journey />} />
                  <Route
                     path=":installationId"
                     element={
                        <ProtectedPage
                           requiredPermissions={Permissions.VIESSMANN_INSTALLATION_SHOW}
                        >
                           <Installation />
                        </ProtectedPage>
                     }
                  />
               </Route>
            </Route>

            {/* TODO - MAKE A PRETTY PAGE IN THE COMPONENT LIBRARY */}
            <Route path="*" element={<p>404</p>} />
         </Routes>
      </BrowserRouter>
   );
}

export default Router;
