import {
   API_ENDPOINTS,
   customToast,
   FormInput,
   Roles,
   route,
   SelectOption,
   Stepper,
   StringHelpers,
   useDisabledContext,
   useLangContext,
   useQueries,
   useUserContext,
   Variants
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import { StepProps } from '../../types/Journey';
import { QUERY_KEYS } from '../../util/Constants';

function Step1({ form, loading, stepLang }: StepProps) {
   const { setValues, store } = form;
   const { crud, fields } = useLangContext();
   const {disabled } = useDisabledContext()

   const [selects, setSelects] = useState<{ [key: string]: SelectOption[] }>({
      installer_companies: [],
      installer_users: [],
   });
   const { user } = useUserContext();

   const { data, error, isLoading } = useQueries([
      {
         key: QUERY_KEYS.INSTALLER_COMPANIES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.SELECT.COMPANIES}?types[]=installer`),
         },
      },
      {
         key: QUERY_KEYS.INSTALLER_USERS,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.SELECT.USERS}?roles[]=engineer`),
         },
      },
   ]);

   useEffect(() => {
      // Handle API data and error
      if (data) {
         setSelects({
            installer_companies: data.installer_companies?.data ?? [],
            installer_users: data.installer_users?.data ?? [],
         });
      }

      if (error) {
         Object.values(error).map((e) =>
            customToast({ title: (e?.response?.data as any)?.message, variant: Variants.Danger })
         );
      }

      // Set default values if the user is an engineer
      if (user?.id && user?.role_name === Roles.ENGINEER) {
         setValues({
            installer_user_id: user.id,
            installer_company_id: user?.individual?.company_id,
         });
      } else if (user?.id && user?.role_name === Roles.COMPANY_MANAGER) {
         setValues({
            installer_company_id: user?.individual?.company_id,
         });
      }
   }, [data, error, user]);

   const disableCompanyField =
      (user?.role_name === Roles.COMPANY_MANAGER || user?.role_name === Roles.ENGINEER) &&
      !!store?.installer_company_id;
   const disableEngineerField = user?.role_name === Roles.ENGINEER && !!store?.installer_user_id;

   const disclaimers = useMemo(() => {
      const messages = { engineer: '', company: '' };
      if (user?.role_name === Roles.COMPANY_MANAGER) {
         messages.company = stepLang?.helper_texts?.installer_company || '';
      }
      if (user?.role_name === Roles.ENGINEER) {
         messages.company = stepLang?.helper_texts?.installer_company_individual || '';
         messages.engineer = stepLang?.helper_texts?.installer_individual || '';
      }

      return messages;
   }, [stepLang, user?.role_name]);

   return (
      <Stepper.Step
         loading={isLoading || loading}
         title={stepLang?.title}
         description={stepLang?.description}
      >
         <div className="d-flex flex-column gap-4">
            <div>
               <FormInput
                  name={'installer_company_id'}
                  type="searchable-select"
                  label={StringHelpers.title(fields?.installer_company_id ?? 'Company')}
                  disabled={disableCompanyField || disabled}
                  options={selects.installer_companies}
                  value={form?.store?.installer_company_id}
                  onChange={(val: any) =>
                     form?.setValues({
                        installer_company_id: val,
                     })
                  }
                  noMargin
                  errorMessages={form?.errors?.installer_company_id}
                  description={disableCompanyField ? disclaimers.company : ''}
               />
            </div>
            <div>
               <FormInput
                  name={'installer_user_id'}
                  type="searchable-select"
                  label={StringHelpers.title(fields?.installer_user_id ?? 'Engineer')}
                  disabled={disableEngineerField || disabled}
                  options={selects.installer_users}
                  value={form?.store?.installer_user_id}
                  onChange={(val: any) =>
                     form?.setValues({
                        installer_user_id: val,
                     })
                  }
                  noMargin
                  errorMessages={form?.errors?.installer_user_id}
                  description={disableEngineerField ? disclaimers.engineer : ''}
               />
            </div>
         </div>
      </Stepper.Step>
   );
}

export default Step1;
