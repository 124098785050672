import axios from 'axios';
import {
   API_ENDPOINTS,
   Button,
   FormError,
   FormInput,
   ImageType,
   Permissions,
   replaceKeyWithValue,
   route,
   SelectOption,
   Stepper,
   StringHelpers,
   SubTitle,
   useAuthorisationContext,
   useDisabledContext,
   useLangContext,
   useQueries,
   Variants,
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StepProps } from '../../types/Journey';
import { QUERY_KEYS, SYSTEM_TYPES, TEN_MINUTES } from '../../util/Constants';
import SearchableSelectInput from '../SearchableSelectInput';

const cacheConfig = {
   enabled: true,
   config: {
      expires: TEN_MINUTES,
   },
};

function Step4({ form, stepLang }: StepProps) {
   const [searchParams] = useSearchParams();
   const urlParams = useParams();
   const { hasPermissionAccess } = useAuthorisationContext();
   const { disabled } = useDisabledContext();
   const installationId = searchParams.get('installationId') || urlParams?.installationId;
   const [showGasSafeFields, setShowGasSafeFields] = useState(
      form?.store?.system_type === SYSTEM_TYPES.GAS_BOILER
   );
   const { crud, fields } = useLangContext();
   const [selects, setSelects] = useState<{ [key: string]: SelectOption[] }>({
      customer_companies: [],
      contact_types: [],
      property_types: [],
      contact_titles: [],
   });
   const [customer, setCustomers] = useState<any | null>();

   const { data, error, isLoading, refetch, refetching } = useQueries([
      {
         key: QUERY_KEYS.CUSTOMERS,
         config: {
            method: 'GET',
            url: route(
               `${API_ENDPOINTS.GENERIC.SELECT.CUSTOMERS}?company_id=${form?.store?.installer_company_id}`
            ),
         },
      },
      {
         key: QUERY_KEYS.CONTACT_TYPES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.GAS_SAFE.CONTACT_TYPES}`),
         },
         cache: cacheConfig,
      },
      {
         key: QUERY_KEYS.PROPERTY_TYPES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.GAS_SAFE.PROPERTY_TYPES}`),
         },
         cache: cacheConfig,
      },
      {
         key: QUERY_KEYS.CONTACT_TITLES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.SELECT.TITLES}`),
         },
         cache: cacheConfig,
      },
      {
         key: 'step_2',
         config: {
            method: 'GET',
            url: route(API_ENDPOINTS.REGISTRATION.INSTALLATION.EDIT, {
               installationId: installationId || '',
               step: '2',
            }),
         },
      },
   ]);

   useEffect(() => {
      if (data) {
         setSelects((prev) => ({
            ...prev,
            customer_companies: data?.customer_companies?.data ?? [],
            contact_types: data?.contact_types?.data ?? [],
            property_types: data?.property_types?.data ?? [],
            contact_titles: data?.contact_titles?.data ?? [],
         }));

         if (data?.step_2?.data) {
            form?.setValues({
               system_type: data?.step_2?.data?.system_type,
            });
            setShowGasSafeFields(data?.step_2?.data?.system_type === SYSTEM_TYPES.GAS_BOILER);
         }
      }
   }, [data]);

   useEffect(() => {
      const customerId = form?.store?.customer_company_id;
      if (customerId && customerId !== '') {
         axios
            .get(
               route(API_ENDPOINTS.REGISTRATION.CUSTOMER.VIEW, {
                  customerId: customerId,
               })
            )
            .then((res) => {
               setCustomers(res.data.data);
               form?.setValues({
                  location_new_building:
                     res.data.data?.customer?.primary_site?.location_new_build ?? false,
                  customer_local_authority_code_for_gas_safe:
                     res?.data?.data?.primary_site?.address?.local_authority_code,
                  customer_title: res?.data?.data?.primary_individual?.title,
                  customer_contact_type_for_gas_safe:res?.data?.data?.gas_safe_contact_type
               });
            })
            .catch((err) => console.log(err));
      }
   }, [form?.store?.customer_company_id]);

   const yesNoOptions = useMemo(
      () =>
         crud?.options?.boolean?.map((option: SelectOption) => ({
            ...option,
            value: Number(option.value) === 1 ? true : false,
         })),
      [crud?.options?.boolean]
   );

   const hasCreateCustomerPermissions = useMemo(() => {
      return hasPermissionAccess(Permissions.CUSTOMER_STORE);
   }, [hasPermissionAccess]);

   const clearCustomerData = () => {
      setCustomers(null);
      form?.setValues({
         location_new_building: false,
         customer_local_authority_code_for_gas_safe: '',
         customer_title: '',
         customer_contact_type_for_gas_safe: '',
      });
   }

   const hideCustomerFields = useMemo(() => {
      return !form?.store?.customer_company_id || !customer;
   }, [form?.store?.customer_company_id, customer]);

   return (
      <Stepper.Step loading={isLoading} title={stepLang?.title} description={stepLang?.description}>
         <div className="d-flex flex-column gap-2">
            <SubTitle
               title={crud?.subtitle?.details || 'Details'}
               className="mb-3 w-100"
               style={{ color: '#464C5E' }}
            />
            <div className="d-flex flex-column">
               <div className="d-flex align-items-center justify-content-center gap-2">
                  <SearchableSelectInput
                     style={{
                        marginBottom: '0 !important',
                     }}
                     name={'customer_company_id'}
                     label={StringHelpers.title(fields?.customer_company_id || 'Customer')}
                     placeholder={StringHelpers.title(
                        replaceKeyWithValue(
                           crud?.placeholders?.search || 'Search :model',
                           'model',
                           crud?.models?.customer || 'Customer'
                        )
                     )}
                     options={selects.customer_companies}
                     value={form?.store?.customer_company_id}
                     onChange={(value: any) => {
                        form?.setValues({
                           customer_company_id: value,
                        });
                     }}
                     loading={isLoading || refetching}
                     clearable={true}
                     onClear={clearCustomerData}
                     errorField={!!form?.errors?.customer_company_id}
                     noOptionsMessage={(inputValue: any) =>
                        hasCreateCustomerPermissions ? (
                           <div>
                              <a target="_blank" href="/customers/create">
                                 {stepLang?.helper_texts?.customer_company_id ||
                                    `Please click here to go to the customer create portal`}
                              </a>
                           </div>
                        ) : null
                     }
                     disabled={disabled}
                  />
                  <Button
                     onClick={refetch}
                     disabled={refetching}
                     label={StringHelpers.title(
                        refetching
                           ? crud?.buttons?.refresh?.submitting || 'Refreshing...'
                           : crud?.buttons?.refresh?.default || 'Refresh'
                     )}
                     size="Medium"
                     variant={Variants.Dark}
                  />
               </div>
               <FormError errorMessages={form?.errors?.customer_company_id} />
               {hasCreateCustomerPermissions && (
                  <div>
                     <p className="form-text">
                        {stepLang?.helper_texts?.customer_company_id_continue ||
                           'If the customer is not appearing in the list and is a new customer.'}{' '}
                        <a target="_blank" href="/customers/create">
                           {stepLang?.helper_texts?.customer_company_id ||
                              `Please click here to go to the customer create portal`}
                        </a>
                     </p>
                  </div>
               )}
            </div>

            {showGasSafeFields && (
               <div className="row ">
                  <div className="col-12 col-md-6 ">
                     {form.Input({
                        name: 'customer_title',
                        placeholder: StringHelpers.title(
                           replaceKeyWithValue(
                              crud?.placeholders?.select || 'Select :model',
                              'model',
                              ''
                           )
                        ),
                        label: StringHelpers.title(fields?.customer_title || 'Contact Title'),
                        type: 'select',
                        options: selects?.contact_titles,
                        visible: !hideCustomerFields,
                     })}
                  </div>
                  <div className="col-12 col-md-6 ">
                     {form.Input({
                        name: 'customer_contact_type_for_gas_safe',
                        label: StringHelpers.title(
                           fields?.customer_contact_type_for_gas_safe || 'Contact Type'
                        ),
                        placeholder: StringHelpers.title(
                           replaceKeyWithValue(
                              crud?.placeholders?.select || 'Select :model',
                              'model',
                              ''
                           )
                        ),
                        type: 'select',
                        options: selects.contact_types,
                        visible:!hideCustomerFields,
                     })}
                  </div>
               </div>
            )}
            {!hideCustomerFields && (
               <>
                  <div className="row">
                     <div className="col-12 col-md-6">
                        <FormInput
                           name="first_name"
                           label={StringHelpers.title(fields?.first_name || 'First Name')}
                           value={customer?.primary_individual?.first_name || ''}
                           type="text"
                           disabled
                        />
                     </div>
                     <div className="col-12 col-md-6">
                        <FormInput
                           name="last_name"
                           label={StringHelpers.title(fields?.last_name || 'Last Name')}
                           value={customer?.primary_individual?.last_name || ''}
                           type="text"
                           disabled
                        />
                     </div>
                     <div className="col-12">
                        <FormInput
                           name="email"
                           label={StringHelpers.title(fields?.email || 'Email')}
                           value={customer?.primary_individual?.email || ''}
                           type="text"
                           disabled
                        />
                     </div>
                     <div className="col-12 col-md-6">
                        <FormInput
                           name="mobile_phone_number"
                           label={StringHelpers.title(
                              fields?.mobile_phone_number || 'Mobile Number'
                           )}
                           value={customer?.primary_individual?.mobile_phone_number || ''}
                           type="text"
                           disabled
                        />
                     </div>
                     <div className="col-12 col-md-6">
                        <FormInput
                           name="landline_phone_number"
                           label={StringHelpers.title(
                              fields?.landline_phone_number || 'Landline Number'
                           )}
                           value={customer?.primary_individual?.landline_phone_number || ''}
                           type="text"
                           disabled
                        />
                     </div>
                  </div>

                  {/* Render Addresses Section */}
                  <SubTitle
                     title={crud?.sub_titles?.address || 'Address'}
                     className="mb-3 w-100"
                     style={{ color: '#464C5E' }}
                  />
                  <div className="row">
                     <div className="col-12">
                        <FormInput
                           type="text"
                           label={StringHelpers.title(fields?.address_line_1 || 'Address line 1')}
                           name="address_line_1"
                           value={customer?.primary_site?.address?.address_line_1 || ''}
                           disabled
                        />
                     </div>
                     <div className="col-12">
                        <FormInput
                           type="text"
                           label={StringHelpers.title(fields?.address_line_2 || 'Address line 2')}
                           name="address_line_2"
                           value={customer?.primary_site?.address?.address_line_2 || ''}
                           disabled
                        />
                     </div>
                     <div className="col-12">
                        <FormInput
                           type="text"
                           label={StringHelpers.title(fields?.address_line_3 || 'Address line 3')}
                           name="address_line_3"
                           value={customer?.primary_site?.address?.address_line_3 || ''}
                           disabled
                        />
                     </div>
                     <div className="col-12">
                        <FormInput
                           type="text"
                           label={StringHelpers.title(fields?.town_city || 'Town / City')}
                           name="town_city"
                           value={customer?.primary_site?.address?.town_city || ''}
                           disabled
                        />
                     </div>
                     <div className="col-12 col-md-6">
                        <FormInput
                           type="text"
                           label={StringHelpers.title(fields?.state_county || 'County / State')}
                           name="state_county"
                           value={customer?.primary_site?.address?.state_county || ''}
                           disabled
                        />
                     </div>
                     <div className="col-12 col-md-6">
                        <FormInput
                           type="text"
                           label={StringHelpers.title(
                              fields?.postcode_zipcode || 'Postcode / Zipcode'
                           )}
                           name="postcode_zipcode"
                           value={customer?.primary_site?.address?.postcode_zipcode || ''}
                           disabled
                        />
                     </div>
                  </div>
               </>
            )}

            {!hideCustomerFields &&  <div className="row">
               <div className="col-12 col-md-6">
                  <FormInput
                     type="select"
                     label={StringHelpers.title(fields?.location_new_build || 'New Build')}
                     name="location_new_build"
                     value={form?.store?.location_new_build ?? false}
                     onChange={(value: string | boolean | string[] | ImageType | null) => {
                        form?.setValues({
                           location_new_build:
                              typeof value === 'string' ? JSON.parse(value) : value,
                        });
                     }}
                     options={yesNoOptions}
                     disabled={disabled}
                  />
               </div>
               <div className="col-12 col-md-6">
                  {form.Input({
                     name: 'customer_property_type_for_gas_safe',
                     label: StringHelpers.title(
                        fields?.customer_property_type_for_gas_safe || 'Property Type'
                     ),
                     placeholder: StringHelpers.title(
                        replaceKeyWithValue(
                           crud?.placeholders?.select || 'Select :model',
                           'model',
                           fields?.customer_property_type_for_gas_safe || 'Property Type'
                        )
                     ),
                     type: 'select',
                     options: selects.property_types,
                     visible: form?.store?.system_type === SYSTEM_TYPES.GAS_BOILER,
                  })}
               </div>
            </div>}
            {form.Input({
               name: 'job_reference',
               label: StringHelpers.title(fields?.job_reference || 'Job Reference'),
               type: 'text',
            })}
            <FormInput
               type={'select'}
               name={'subcontractor_exists'}
               value={form?.store?.customer_email_override_exists}
               label={StringHelpers.title(
                  fields?.customer_email_override_exists || 'Override Customer Email'
               )}
               onChange={(value: string | boolean | string[] | ImageType | null) =>
                  form?.setValues({
                     customer_email_override_exists:
                        typeof value === 'string' ? JSON.parse(value) : value,
                  })
               }
               options={yesNoOptions}
               disabled={disabled}
            />
            {form.Input({
               name: 'customer_email_override',
               label: StringHelpers.title(fields?.customer_email_override || 'Customer Email'),
               type: 'text',
               visible: form?.store?.customer_email_override_exists,
            })}
         </div>
      </Stepper.Step>
   );
}

export default Step4;
