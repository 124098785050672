import {
   API_ENDPOINTS,
   customToast,
   route,
   Modal,
   StringHelpers,
   useLangContext,
   Variants,
   useDisabledContext,
   formatDate
}
   from 'carrier-fe';
import React, { useState } from 'react';
import axios from 'axios';
import { InstallationType } from '../types/installationType';
import { useNavigate } from 'react-router-dom';

function ArchiveCancelModal({
   modalOpen,
   modalClose,
   title,
   type,
   onSuccess,
   installationId,
   storeData
}: {
   modalOpen: boolean;
   modalClose: () => void;
   title: string;
   type: 'archive' | 'cancel';
   onSuccess?: () => Promise<void>
   installationId: string;
   storeData: InstallationType;
}) {

   const navigate = useNavigate();
   const { crud, fields } = useLangContext();
   const { disabled, setDisabled } = useDisabledContext();

   const [isProcessing, setIsProcessing] = useState(false);

   const handleAction = async () => {
      setDisabled(true);
      setIsProcessing(true);

      try {
         const endpoint = type === 'archive'
            ? API_ENDPOINTS.REGISTRATION.INSTALLATION.DELETE
            : API_ENDPOINTS.REGISTRATION.INSTALLATION.CANCEL;

         const method = type === 'archive' ? 'delete' : 'patch';

         let response = await axios[method](
            route(endpoint, {
               installationId: String(installationId),
            })
         );

         customToast({
            title: response?.data?.message || '',
            variant: Variants.Success,
         });

         if (onSuccess) {
            await onSuccess();
         }

         if (type === 'archive') {
            navigate('/installation');
         }

         modalClose();
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message || '',
            variant: Variants.Danger,
         });
      } finally {
         setDisabled(false);
         setIsProcessing(false);
      }
   };

   const getButtonText = () => {
      if (type === 'archive') {
         return isProcessing
            ? crud?.buttons.archive.submitting || 'Archiving...'
            : crud?.buttons.archive.default || 'Archive';
      } else {
         return isProcessing
            ? crud?.buttons?.cancel?.submitting || 'Cancelling...'
            : crud?.buttons?.cancel?.default || 'Cancel';
      }
   };

   const getCloseText = () => {
      return type === 'archive'
         ? crud?.buttons?.cancel.default || 'Cancel'
         : crud?.buttons.close?.default || 'Close';
   };

   return (
      <Modal
         open={modalOpen}
         onClose={modalClose}
         title={StringHelpers.title(title)}
         confirmText={getButtonText()}
         closeText={getCloseText()}
         onConfirm={handleAction}
         disabled={disabled || isProcessing}
         variant={Variants.Danger}
      >
         <div>
            <p><strong>{StringHelpers.title(fields?.company_name || 'Company')}: </strong>{StringHelpers.title(storeData?.installer_company?.name || '-')}</p>
            <p><strong>{StringHelpers.title(fields?.installer_individual || 'Engineer')}: </strong>{StringHelpers.title(storeData?.installer_individual?.name || '-')}</p>
            <p><strong>{StringHelpers.title(fields?.completion_date || 'Work Completion Date')}: </strong>{storeData?.completion_date ? formatDate(storeData?.completion_date) : '-'}</p>
            <p><strong>{StringHelpers.title(crud?.models?.customer || 'Customer')}: </strong>{StringHelpers.title(storeData?.customer_company?.primary_individual?.name || '-')}</p>
            <p><strong>{StringHelpers.title(fields?.system_type || 'System Type')}: </strong>{StringHelpers.title(storeData?.system_type_display || '-')}</p>
         </div>
      </Modal>
   )
}

export default ArchiveCancelModal;