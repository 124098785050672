import {
   API_ENDPOINTS,
   BottomAnchoredControls,
   Button,
   ButtonTypes,
   Card,
   CardBody,
   customToast,
   PageLayout,
   ProgressBar,
   replaceKeyWithValue,
   route,
   Stepper,
   StringHelpers,
   useDisabledContext,
   useForm,
   useLangContext,
   useNavigationGuard,
   useScrollToError,
   useStepper,
   Variants,
   WidthConstrainedContainer,
} from 'carrier-fe';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Step1 from '../../../components/installation-journey/Step1';
import Step2 from '../../../components/installation-journey/Step2';
import Step3 from '../../../components/installation-journey/Step3';
import Step4 from '../../../components/installation-journey/Step4';
import Step5, { defaultApplianceItem } from '../../../components/installation-journey/Step5';
import Step6 from '../../../components/installation-journey/Step6';
import axios from 'axios';
import { ApplianceDetails, InstallationResponse } from '../../../types/Installation';
import { configurePayload, loadPageLang } from '../../../util/journey-utils';
import { DEFAULT_NAVIGATION_GUARD, STEPS, TOTAL_STEPS } from '../../../util/Constants';
import { StepsLangPage } from '../../../types/Journey';

const defaultData = {
   installer_company_id: '',
   installer_user_id: '',
   installer_individual: '',
   completion_date: '',
   system_type: '',
   subcontractor_exists: false,
   subcontractor_address_id: '',
   subcontractor_address_line_1: '',
   subcontractor_address_line_2: '',
   subcontractor_address_line_3: '',
   subcontractor_sub_country_name: '',
   subcontractor_local_authority_code: '',
   subcontractor_town_city: '',
   subcontractor_state_county: '',
   subcontractor_postcode_zipcode: '',
   subcontractor_country_code_iso_3: '',
   subcontractor_company_name: '',
   subcontractor_company_gas_safe_number_confirmed: false,
   subcontractor_company_gas_safe_number: '',
   subcontractor_engineer_first_name: '',
   subcontractor_engineer_last_name: '',
   subcontractor_engineer_gas_safe_licence_card_number_confirmed: false,
   subcontractor_engineer_gas_safe_licence_card_number: '',
   customer_company_id: '',
   customer_title: '',
   location_new_building: false,
   customer_contact_type_for_gas_safe: '',
   customer_local_authority_code_for_gas_safe: '',
   customer_property_type_for_gas_safe: '',
   location_new_build: false,
   customer_email_override_exists: false,
   customer_email_override: '',
   job_reference: '',
   appliances: [
      {
         merchant_id: '',
         appliance_details:{
            appliance_type: '',
            full_name: '',
            installation_type: '',
            model: '',
            version: '',
         } as ApplianceDetails,
         serial_number: '',
         other_merchant_name: '',
         installer_will_service: 1,
         servicing_details: '',
         gas_safe_product_location: '',
         gas_safe_work_category_id: '',
         gas_safe_work_category: '',
         viessmann_will_contact_customer_about_servicing: true,
      },
   ],
   confirmed: false,
};

export type InstallationFormData = typeof defaultData;

function Journey() {
   const { installationId } = useParams();
   const { crud, getLangPage } = useLangContext();
   // Warn before navigation

   const [searchParams] = useSearchParams();
   const { disabled, setDisabled } = useDisabledContext();
   const [loading, setLoading] = useState(false);
   const [pageLang, setPageLang] = useState<StepsLangPage>();
   const { activeStep, nextStep, prevStep, isFirstStep, isLastStep } = useStepper({
      totalSteps: TOTAL_STEPS,
   });

   useNavigationGuard({
      message: crud?.modals?.alert?.description?.at(0) || DEFAULT_NAVIGATION_GUARD,
      isEnabled: !isLastStep,
   });

   const isEdit = installationId ? true : false;
   const navigate = useNavigate();

   useEffect(() => {
      if (installationId || searchParams.get('installationId')) {
         loadStepData();
      }
      loadPageLang(pageLang, getLangPage, setPageLang);
   }, [activeStep]);

   console.log(pageLang)

   const update = async (data: Partial<InstallationFormData>): Promise<any> => {
      const payload = configurePayload(data, activeStep);
      const installationIdFromSearchParams = searchParams.get('installationId');
      const installationIdToUse = installationId || installationIdFromSearchParams;

      let response;
      // Create Installation - Initial step
      if (isFirstStep && !isEdit && !installationIdFromSearchParams) {
         response = await axios.post<{ data: InstallationResponse; message: string }>(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.STORE),
            payload
         );
         // Patch on every other step
      } else if (installationIdToUse && activeStep) {
         response = await axios.patch<{ data: InstallationResponse; message: string }>(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.UPDATE, {
               installationId: installationIdToUse || '',
               step: String(activeStep),
            }),
            payload
         );
      } else {
         throw new Error('Unable to submit. Missing required data');
      }

      // Item created. happens at step 1 only
      if (response?.status === 201) {
         const { data } = response;
         nextStep({
            installationId: data?.data?.id,
         });
         // every other call
      } else if (response?.status === 200) {
         const { data } = response;
         if (isLastStep) {
            setDisabled(false);
            navigate(`/installation/${data?.data?.id}`);
         } else {
            nextStep();
         }
      }
   };

   const form = useForm(update, defaultData);
   const formRef = useScrollToError(form.errors);
   const { Submit, setValues, store } = form;

   const handleBack = () => {
      prevStep();
   };

   const title = useMemo(
      () =>
         isEdit
            ? replaceKeyWithValue(
                 crud?.pages?.edit.title || 'Edit :model',
                 'model',
                 crud?.models?.installation || 'Installation'
              )
            : replaceKeyWithValue(
                 crud?.pages?.create.title || 'Create :model',
                 'model',
                 crud?.models?.installation || 'Installation'
              ),
      [crud, isEdit]
   );

   const loadStepData = async () => {
      setLoading(true);
      setDisabled(true);
      try {
         const stepData = await axios.get<{
            data: Partial<InstallationFormData>;
            message: string;
         }>(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.EDIT, {
               installationId: installationId || searchParams.get('installationId') || '',
               step: String(activeStep),
            })
         );

         const appliances =
            stepData?.data?.data?.appliances?.length === 0
               ? [defaultApplianceItem]
               : stepData?.data?.data?.appliances;

         setValues({
            ...store,
            ...stepData?.data?.data,
            appliances,
         });
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message,
            variant: Variants.Danger,
         });
      } finally {
         setLoading(false);
         setDisabled(false);
      }
   };

   const btnLabel = useMemo(() => {
      const stepType = isLastStep ? 'confirm' : 'next';
      const defaultLabel = crud?.buttons?.[stepType]?.default || (isLastStep ? 'Confirm' : 'Next');
      const loadingLabel =
         crud?.buttons?.[stepType]?.submitting || (isLastStep ? 'Confirming...' : 'Saving...');

      return { loadingLabel, defaultLabel };
   }, [isLastStep, crud]);

   return (
      <PageLayout
         title={StringHelpers.title(title)}
         progressBar={{ activeStep, totalSteps: TOTAL_STEPS }}
      >
         <WidthConstrainedContainer>
            <Card style={{ maxWidth: '1440px' }}>
               <CardBody>
                  <div ref={formRef}>
                      <Stepper
                        title={StringHelpers.upper(`${crud?.sub_titles?.step || 'step'} ${activeStep}/${TOTAL_STEPS}`)}
                        activeStep={activeStep}
                      >
                        {[Step1, Step2, Step3, Step4, Step5, Step6].map((StepComponent, index) => (
                           <StepComponent
                             key={index}
                             form={form}
                             loading={loading}
                             stepLang={{
                               ...pageLang?.page?.steps?.[index],
                               helper_texts: pageLang?.page?.helper_texts,
                               generic: pageLang?.generic,
                             }}
                           />
                        ))}
                      </Stepper>
                  </div>
               </CardBody>
            </Card>
         </WidthConstrainedContainer>
         <BottomAnchoredControls>
            <div className="me-auto ms-5">
               <Button
                  label={StringHelpers.title(crud?.buttons?.exit?.default || 'Exit')}
                  onClick={() => {
                     window.confirm(
                        crud?.modals?.alert?.description?.at(0) || DEFAULT_NAVIGATION_GUARD
                     ) && navigate('/installation');
                  }}
                  variant={Variants.Dark}
                  type={ButtonTypes.Outline}
                  disabled={disabled}
               />
            </div>
            {!isFirstStep && (
               <Button
                  label={crud?.buttons?.previous?.default || 'Previous'}
                  onClick={handleBack}
                  variant={Variants.Primary}
                  type={ButtonTypes.Outline}
                  className={'me-4'}
                  disabled={disabled}
               />
            )}
            <Submit
               disabled={disabled}
               defaultLabel={btnLabel.defaultLabel}
               loadingLabel={btnLabel.loadingLabel}
            />
         </BottomAnchoredControls>
      </PageLayout>
   );
}

export default Journey;
