export const DEFAULT_NAVIGATION_GUARD =
   'Are you sure you want to leave this page? You will lose any unsaved changes for the current step.' as const;

export enum STEPS {
   STEP_1 = 1,
   STEP_2 = 2,
   STEP_3 = 3,
   STEP_4 = 4,
   STEP_5 = 5,
   STEP_6 = 6,
};

export const TOTAL_STEPS = 6 as const;

export const MAX_APPLIANCES = 10 as const;

export const TEN_MINUTES = 10 / (24 * 60);

export enum QUERY_KEYS {
   PROPERTY_TYPES = 'property_types',
   CONTACT_TITLES = 'contact_titles',
   CONTACT_TYPES = 'contact_types',
   WORK_CATEGORIES = 'work_categories',
   PRODUCT_LOCATIONS = 'product_locations',
   MERCHANTS = 'merchants',
   CUSTOMERS = 'customer_companies',
   INSTALLER_USERS = 'installer_users',
   INSTALLER_COMPANIES = 'installer_companies',
};

export enum SYSTEM_TYPES {
   GAS_BOILER = 'gas_boiler',
   HEAT_PUMPS = 'heat_pump',
   SOLAR = 'solar',
   OTHER = 'other',
}

export enum STATUS_TYPES {
   COMPLETED = 'completed',
   IN_PROGRESS = 'in_progress',
   CANCELLED = 'cancelled'
}

export enum MERCHANT_IDS {
   OTHER = 'other',
}