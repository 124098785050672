import axios from 'axios';
import {
   API_ENDPOINTS,
   Button,
   Card,
   CardData,
   customToast,
   FormError,
   FormInput,
   replaceKeyWithValue,
   route,
   SelectOption,
   Stepper,
   StringHelpers,
   SubTitle,
   useArrayField,
   useDisabledContext,
   useLangContext,
   useQueries,
   useStore,
   Variants,
} from 'carrier-fe';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { InstallationFormData } from '../../pages/installation/journey';
import { ApplianceDetails, InstallationResponse } from '../../types/Installation';
import { StepProps } from '../../types/Journey';
import {
   MAX_APPLIANCES,
   MERCHANT_IDS,
   QUERY_KEYS,
   SYSTEM_TYPES,
   TEN_MINUTES,
} from '../../util/Constants';

export const defaultApplianceItem = {
   merchant_id: '',
   serial_number: '',
   other_merchant_name: '',
   installer_will_service: 1,
   servicing_details: '',
   gas_safe_product_location: '',
   gas_safe_work_category_id: '',
   gas_safe_work_category: '',
   viessmann_will_contact_customer_about_servicing: true,
   appliance_details: {
      appliance_type: '',
      full_name: '',
      installation_type: '',
      model: '',
      version: '',
   } as ApplianceDetails,
};

const cacheConfig = {
   enabled: true,
   config: {
      expires: TEN_MINUTES,
   },
};

function Step5({ form, loading: parentLoading, stepLang }: StepProps) {
   const params = useParams();
   const [searchParams] = useSearchParams();

   const [fetchingWorkCategories, setFetchingWorkCategories] = useState(false);
   const installationId = params?.installationId || searchParams?.get('installationId') || '';
   const { crud, fields } = useLangContext();
   const { disabled, setDisabled } = useDisabledContext();
   const [selects, setSelects] = useState<{ [key: string]: SelectOption[] }>({
      work_categories: [],
      product_locations: [],
      merchants: [],
   });
   const [fetchingAppliance, setFetchingAppliance] = useState(false);

   const { store: applianceLookups, setValues: setApplianceLookups } = useStore<{
      [key: string]: ApplianceDetails;
   }>({});

   const { data, isLoading, error } = useQueries([
      {
         key: 'installation',
         config: {
            method: 'GET',
            url: route(API_ENDPOINTS.REGISTRATION.INSTALLATION.VIEW, { installationId }),
         },
      },
      {
         key: QUERY_KEYS.MERCHANTS,
         config: {
            method: 'GET',
            url: route(API_ENDPOINTS.GENERIC.SELECT.MERCHANTS),
         },
         cache: cacheConfig,
      },
      {
         key: QUERY_KEYS.PRODUCT_LOCATIONS,
         config: {
            method: 'GET',
            url: route(API_ENDPOINTS.GENERIC.GAS_SAFE.PRODUCT_LOCATIONS),
         },
         cache: cacheConfig,
      },
   ]);

   const {
      add,
      remove,
      values: appliances,
      update,
   } = useArrayField<InstallationFormData, InstallationFormData['appliances'][number]>({
      name: 'appliances',
      form: form,
      defaultValue: [defaultApplianceItem],
      rules: {
         max: MAX_APPLIANCES,
      },
   });

   useEffect(() => {
      if (error) {
         Object.values(error).map((e) =>
            customToast({ title: (e?.response?.data as any)?.message, variant: Variants.Danger })
         );
      }
      if (data) {
         setSelects({
            product_locations: data?.product_locations?.data ?? [],
            merchants: data?.merchants?.data ?? [],
         });
      }
   }, [data, error]);

   useEffect(() => {
      if (data?.installation) {
         const installation = data?.installation?.data as InstallationResponse;
         form?.setValues({
            system_type: installation?.system_type || '',
         });
         if(form?.store?.appliances){
            Promise.all(form?.store?.appliances?.map((_, index) => findAppliance(index)));
         }
      }
   }, [data?.installation]);

   const handleChange = (
      index: number,
      field: keyof InstallationFormData['appliances'][number],
      value: string | number
   ) => {
      const applianceItem = appliances[index];
      const updatedItem = {
         ...applianceItem,
         [field]: field === 'installer_will_service' ? Number(value) : value,
      };
      update(index, updatedItem);
   };

   const handleAdd = () => add(defaultApplianceItem);

   const findAppliance = async (index: number) => {
      setFetchingAppliance(true);
      setDisabled(true);
      try {
         if (!appliances[index]?.serial_number) return;
         const response = await axios.get<{ data: ApplianceDetails; message: string }>(
            route(
               `${API_ENDPOINTS.GENERIC.LOOKUP.VIESSMANN_SERIAL_NUMBER}?serial_number=${appliances[index]?.serial_number}&individual_id=${data?.installation?.data?.installer_individual_id}&completion_date=${data?.installation?.data?.completion_date}`
            )
         );
         update(index, {
            ...appliances[index],
            appliance_details: response?.data?.data,
         });
         setSelects({
            ...selects,
            work_categories: response?.data?.data?.work_categories || [],
         });
         customToast({
            title: response?.data?.message,
            variant: Variants.Success,
         });
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message,
            variant: Variants.Danger,
         });
      } finally {
         setFetchingAppliance(false);
         setDisabled(false);
      }
   };

   const isGasBoiler = useMemo(
      () =>
         form?.store?.system_type === SYSTEM_TYPES.GAS_BOILER ||
         data?.installation?.data?.system_type === SYSTEM_TYPES.GAS_BOILER,
      [form?.store?.system_type, data?.installation?.data?.system_type]
   );

   const yesNoOptions = useMemo(
      () =>
         crud?.options?.boolean?.map((option: SelectOption) => ({
            ...option,
            value: Number(option.value),
         })),
      [crud?.options?.boolean]
   );

   const isComponentLoading = useMemo(
      () => isLoading || fetchingWorkCategories || parentLoading,
      [isLoading, fetchingWorkCategories, parentLoading]
   );

   console.log(stepLang)

   return (
      <Stepper.Step
         loading={isComponentLoading}
         title={stepLang?.title}
         description={stepLang?.description}
      >
         <>
            {appliances?.map((item, index) => (
               <div className="mb-3" key={index}>
                  <Card
                     noMargin
                     className={`p-4 ${form?.errors?.appliances ? 'border-danger' : ''}`}
                  >
                     <div className="d-flex justify-content-between align-items-center mb-4">
                        <SubTitle
                           title={StringHelpers.title(
                              `${crud?.models?.appliance || 'Appliance'} ${index + 1}`
                           )}
                        />
                        {appliances?.length > 1 && (
                           <Button
                              variant={Variants.Danger}
                              type="OUTLINE"
                              label={StringHelpers.title(
                                 replaceKeyWithValue(
                                    crud?.buttons?.remove?.dynamic?.default || 'Remove :any',
                                    'any',
                                    crud?.models?.appliance || 'Appliance'
                                 )
                              )}
                              size="Small"
                              onClick={() => remove(index)}
                              disabled={disabled}
                           />
                        )}
                     </div>
                     <div className="d-flex flex-column">
                        <div className="mb-4">
                           <div className="d-flex align-items-start gap-2 justify-content-between">
                              <div className="flex-fill">
                                 <FormInput
                                    noMargin
                                    name={`name-${index}-serial_number`}
                                    label={StringHelpers.title(
                                       fields?.serial_number || 'Serial Number'
                                    )}
                                    type="text"
                                    value={item?.serial_number}
                                    onChange={(value) =>
                                       handleChange(index, 'serial_number', value as any)
                                    }
                                    errorMessages={
                                       form?.errors?.[`appliances.${index}.serial_number`]
                                    }
                                    disabled={disabled}
                                 />
                              </div>
                              <Button
                                 disabled={!item?.serial_number || disabled}
                                 variant={Variants.Dark}
                                 type="SOLID"
                                 label={StringHelpers.title(
                                    fetchingAppliance
                                       ? crud?.buttons?.find?.submitting || 'Searching... '
                                       : replaceKeyWithValue(
                                            crud?.buttons?.find?.dynamic?.default || 'Find :any',
                                            'any',
                                            crud?.models?.appliance || 'Appliance'
                                         )
                                 )}
                                 size="Medium"
                                 onClick={() => findAppliance(index)}
                              />
                           </div>
                           {/* TODO: Appliance details card */}
                           {item?.serial_number && item?.appliance_details?.full_name && (
                              <Card className="p-3 mt-2" noMargin>
                                 <div className="d-flex justify-content-between">
                                    <CardData.ColumnItem
                                       title={stepLang?.generic?.appliance_details_full_name || ''}
                                       value={item?.appliance_details?.full_name}
                                    />
                                    <CardData.ColumnItem
                                       title={stepLang?.generic?.appliance_details_installation_type || ''}
                                       value={item?.appliance_details?.installation_type}
                                    />
                                    <CardData.ColumnItem
                                       title={stepLang?.generic?.appliance_model || ''}
                                       value={item?.appliance_details?.model}
                                    />
                                    <CardData.ColumnItem
                                       title={stepLang?.generic?.appliance_details_version || ''}
                                       value={item?.appliance_details?.version}
                                    />
                                 </div>
                              </Card>
                           )}
                        </div>
                        {isGasBoiler && item?.appliance_details?.full_name && (
                           <div className="row">
                              <div className="col-md-6">
                                 <FormInput
                                    name={`name-${index}-product_location`}
                                    type="select"
                                    label={StringHelpers.title(
                                       fields?.product_location || 'Product Location'
                                    )}
                                    placeholder={StringHelpers.title(
                                       replaceKeyWithValue(
                                          crud?.placeholders?.select?.default || 'Select :model',
                                          'model',
                                          fields?.product_location || 'Product Location'
                                       )
                                    )}
                                    options={selects?.product_locations}
                                    value={item?.gas_safe_product_location}
                                    onChange={(value) =>
                                       handleChange(
                                          index,
                                          'gas_safe_product_location',
                                          value as any
                                       )
                                    }
                                    errorMessages={
                                       form?.errors?.[
                                          `appliances.${index}.gas_safe_product_location`
                                       ]
                                    }
                                    disabled={disabled}
                                 />
                              </div>
                              <div className="col-md-6">
                                 <FormInput
                                    name={`name-${index}-work_category`}
                                    type="select"
                                    label={StringHelpers.title(
                                       fields?.work_category || 'Work Category'
                                    )}
                                    placeholder={StringHelpers.title(
                                       replaceKeyWithValue(
                                          crud?.placeholders?.select?.default || 'Select :model',
                                          'model',
                                          fields?.work_category || 'Work Category'
                                       )
                                    )}
                                    options={selects?.work_categories}
                                    value={item?.gas_safe_work_category_id as any}
                                    onChange={(value) =>
                                       handleChange(
                                          index,
                                          'gas_safe_work_category_id',
                                          value as any
                                       )
                                    }
                                    errorMessages={
                                       form?.errors?.[
                                          `appliances.${index}.gas_safe_work_category_id`
                                       ]
                                    }
                                    disabled={disabled}
                                 />
                              </div>
                           </div>
                        )}
                        {item?.appliance_details?.full_name && (
                           <FormInput
                              name={`name-${index}-merchant`}
                              type="select"
                              label={StringHelpers.title(fields?.merchant_id || 'Merchant')}
                              placeholder={StringHelpers.title(
                                 replaceKeyWithValue(
                                    crud?.placeholders?.select?.default || 'Select :model',
                                    'model',
                                    fields?.merchant_id || 'Merchant'
                                 )
                              )}
                              options={selects?.merchants}
                              value={item?.merchant_id}
                              onChange={(value) => handleChange(index, 'merchant_id', value as any)}
                              errorMessages={form?.errors?.[`appliances.${index}.merchant_id`]}
                              disabled={disabled}
                           />
                        )}
                        {item?.merchant_id?.toLowerCase() === MERCHANT_IDS.OTHER && (
                           <FormInput
                              name={`name-${index}-merchant_other`}
                              type="text"
                              label={StringHelpers.title(
                                 fields?.other_merchant_name || 'Merchant other'
                              )}
                              value={item?.other_merchant_name}
                              onChange={(value) =>
                                 handleChange(index, 'other_merchant_name', value as any)
                              }
                              errorMessages={
                                 form?.errors?.[`appliances.${index}.other_merchant_name`]
                              }
                              disabled={disabled}
                           />
                        )}
                        <SubTitle
                           title={StringHelpers.title('Servicing Disclaimer')}
                           className={'mb-3'}
                        />
                        <FormInput
                           name={`name-${index}-service_maintained`}
                           type="select"
                           placeholder={StringHelpers.title(
                              replaceKeyWithValue(
                                 crud?.placeholders?.select?.default || 'Select :model',
                                 'model',
                                 ''
                              )
                           )}
                           label={StringHelpers.sentence(
                              fields?.installer_will_service ||
                                 'Do you intend to service and maintain this product?'
                           )}
                           value={item?.installer_will_service as any}
                           options={yesNoOptions}
                           onChange={(value) =>
                              handleChange(index, 'installer_will_service', value as any)
                           }
                           errorMessages={
                              form?.errors?.[`appliances.${index}.installer_will_service`]
                           }
                           disabled={disabled}
                        />
                        {!item?.installer_will_service && (
                           <>
                              <FormInput
                                 name={`name-${index}-service_maintained_details`}
                                 type="textarea"
                                 label={StringHelpers.title(
                                    fields?.servicing_details || 'Service Maintained Details'
                                 )}
                                 value={item?.servicing_details}
                                 onChange={(value) =>
                                    handleChange(index, 'servicing_details', value as any)
                                 }
                                 errorMessages={
                                    form?.errors?.[`appliances.${index}.servicing_details`]
                                 }
                                 disabled={disabled}
                              />

                              <FormInput
                                 name={`name-${index}-viessmann_contact_customer`}
                                 type="select"
                                 label={StringHelpers.sentence(
                                    fields?.viessmann_will_contact_customer_about_servicing ||
                                       'Viessmann Contact Customer'
                                 )}
                                 value={item?.viessmann_will_contact_customer_about_servicing}
                                 onChange={(value) =>
                                    handleChange(
                                       index,
                                       'viessmann_will_contact_customer_about_servicing',
                                       typeof value === 'string' ? JSON.parse(value) : value
                                    )
                                 }
                                 options={crud?.options?.boolean.map((option: SelectOption) => ({
                                    ...option,
                                    value: Number(option.value) === 1 ? true : false,
                                 }))}
                                 errorMessages={
                                    form?.errors?.[
                                       `appliances.${index}.viessmann_will_contact_customer_about_servicing`
                                    ]
                                 }
                                 disabled={disabled}
                              />
                           </>
                        )}
                     </div>
                  </Card>
                  <FormError errorMessages={form?.errors?.appliances} />
               </div>
            ))}
            {appliances?.length < MAX_APPLIANCES && (
               <Button
                  disabled={appliances?.length >= MAX_APPLIANCES || disabled}
                  label={StringHelpers.title(
                     replaceKeyWithValue(
                        crud?.buttons?.add?.dynamic?.default || 'Add :any',
                        'any',
                        crud?.models?.appliance || 'Appliance'
                     )
                  )}
                  size="Medium"
                  onClick={handleAdd}
                  variant={Variants.Dark}
               />
            )}
         </>
      </Stepper.Step>
   );
}

export default Step5;
