import {
	PageLayout,
	Card,
	CardBody,
	Button,
	CardPlaceholder,
	Permissions,
	Icon,
	Icons,
	useAuthorisationContext,
	useLangContext,
	StringHelpers,
	checkTheme,
	Themes,
	Variants,
} from 'carrier-fe';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
	const { hasPermissionAccess, hasPolicyAccess } = useAuthorisationContext();
	const { getLangPage } = useLangContext();

	const [lang, setLang] = useState<any>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	const load = async () => {
		getLangPage('registration/dashboard')
			.then((res: any) => {
				if (typeof res === 'undefined' || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = StringHelpers.title(
						res?.page.title ?? 'Dashboard'
					);
				}
			})
			.catch((err: any) => console.error(err));
	};

	const getTileById = (id: string) => {
		if (!lang || !lang.page || !lang.page.tiles) return null;

		for (let i = 0; i < lang.page.tiles.length; i++) {
			if (lang.page.tiles[i]['id'] === id) {
				return lang.page.tiles[i];
			}
		}

		return null;
	};

	const renderGasBoilerTile = () => {
		if (
			!checkTheme(
				Themes.Toshiba ||
					!hasPermissionAccess(Permissions.TOSHIBA_REGISTRATION_INDEX)
			) &&
			!checkTheme(
				Themes.Viessmann ||
					!hasPermissionAccess(
						Permissions.VIESSMANN_REGISTRATION_INDEX
					)
			)
		)
			return null;

		let content = getTileById('registration.gas.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Register a Gas Boiler'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	};

	const renderHeatPumpTile = () => {
		if (
			(!checkTheme(Themes.Toshiba) ||
				!hasPermissionAccess(Permissions.TOSHIBA_WARRANTY_INDEX)) &&
			(!checkTheme(Themes.Viessmann) ||
				!hasPermissionAccess(Permissions.VIESSMANN_WARRANTY_INDEX))
		)
			return null;

		let content = getTileById('registration.heat.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Register a Heat Pump'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	};

	const renderSolarProductTile = () => {
		if (
			(!checkTheme(Themes.Toshiba) ||
				!hasPermissionAccess(Permissions.TOSHIBA_WARRANTY_INDEX)) &&
			(!checkTheme(Themes.Viessmann) ||
				!hasPermissionAccess(Permissions.VIESSMANN_WARRANTY_INDEX))
		)
			return null;

		let content = getTileById('registration.solar.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Register a Solar product'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	};

	const renderElectricProductTile = () => {
		if (!hasPermissionAccess(Permissions.CUSTOMER_INDEX)) return null;

		let content = getTileById('registration.electric.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Register an Electric product'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	};

	const renderInstallationsTile = () => {
		if (
			!hasPolicyAccess({
				name: 'viessmann_installation',
				method: 'viewAny',
			}) &&
			!hasPolicyAccess({
				name: 'toshiba_installation',
				method: 'viewAny',
			})
		)
			return null;

		let content = getTileById('registration.installations.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.APARTMENT}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'View my previous installations'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: ''}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate('/installation')}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	};

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title ?? 'Dashboard')}
		>
			<div className="dashboard--inner--wrapper d-grid gap-4">
				{lang === null ? (
					<>
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
					</>
				) : (
					<>
						{renderGasBoilerTile()}
						{renderHeatPumpTile()}
						{renderSolarProductTile()}
						{renderElectricProductTile()}
						{renderInstallationsTile()}
					</>
				)}
			</div>
		</PageLayout>
	);
}

export default Dashboard;
