import {
   API_ENDPOINTS,
   customToast,
   route,
   FormInput,
   ImageType,
   Modal,
   StringHelpers,
   useLangContext,
   Variants,
   useDisabledContext
}
   from 'carrier-fe';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { InstallationType } from '../types/installationType';
import { useParams } from 'react-router-dom';

function ConfirmationModal({
   modalOpen,
   modalClose,
   title,
   dynamicText,
   defaultEmail,
   type,
   onSuccess,
   storeData
}: {
   onSuccess?: () => void;
   modalOpen: boolean;
   modalClose: () => void;
   title: string;
   dynamicText: string;
   disabled?: boolean;
   defaultEmail: string
   type: 'gasSafe' | 'warranty' | 'warrantySelection';
   storeData: InstallationType;
   emailEnabled?: boolean;
}) {
   const { crud } = useLangContext();
   const { disabled, setDisabled } = useDisabledContext();
   const { installationId } = useParams();

   const [isSending, setIsSending] = useState(false);
   const [email, setEmail] = useState(defaultEmail);

   useEffect(() => {
      if (modalOpen) {
         setEmail(storeData?.customer_company?.primary_individual?.email || '');
      } else {
         setEmail('');
      }
   }, [modalOpen, storeData?.customer_company?.primary_individual?.email]);

   const handleEmailChange = (value: string | boolean | ImageType | string[] | null) => {
      if (typeof value === 'string') {
         setEmail(value);
      }
   };

   const handleSendEmail = async (confirmSend: boolean) => {
      if (!confirmSend) return;

      try {
         setIsSending(true);
         setDisabled(true);

         const emailToUse = email.trim() || storeData?.customer_company?.primary_individual?.email;
         let endpoint;

         if (type === 'gasSafe') {
            endpoint = API_ENDPOINTS.REGISTRATION.INSTALLATION.SEND_GAS_SAFE_EMAIL;
         } else if (type === 'warranty') {
            endpoint = API_ENDPOINTS.REGISTRATION.INSTALLATION.SEND_GAS_SAFE_EMAIL; //change to SEND_WARRANTY_EMAIL not implemented
         } else if (type === 'warrantySelection') {
            endpoint = API_ENDPOINTS.REGISTRATION.INSTALLATION.SEND_GAS_SAFE_EMAIL; //change to warranty prompt
         }

         if (!endpoint) {
            throw new Error('Invalid email type');
         }

         const response = await axios.patch(
            route(endpoint, {
               installationId: String(installationId),
            }),
            {
               email: emailToUse
            }
         );

         customToast({
            title: response?.data?.message || '',
            variant: Variants.Success
         });

         onSuccess?.();
         modalClose();
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message || '',
            variant: Variants.Danger
         });
      } finally {
         setIsSending(false);
         setDisabled(false);
      }
   };

   return (
      <Modal
         open={modalOpen}
         onClose={modalClose}
         title={StringHelpers.title(title)}
         confirmText={
            isSending
               ? crud?.buttons?.send?.submitting || 'Sending...'
               : crud?.buttons?.send?.default || 'Send'
         }
         closeText={crud?.buttons.cancel?.default || 'Cancel'}
         onConfirm={() => handleSendEmail(true)}
         disabled={disabled || isSending}
         variant={Variants.Danger}
      >
         <div>
            <p>{dynamicText}</p>
            <div className="mt-4">
               <div className="w-full">
                  <FormInput
                     type="text"
                     name="new_email"
                     value={email}
                     onChange={handleEmailChange}
                     placeholder="Enter new email address"
                  />
               </div>
            </div>
         </div>
      </Modal>
   );
}

export default ConfirmationModal;
