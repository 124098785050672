import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { StepProps } from '../../types/Journey';
import {
	API_ENDPOINTS,
	Button,
	customToast,
	FormInput,
	PostcodeLookup,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	Variants,
	Stepper,
	useLangContext,
	replaceKeyWithValue,
	Card,
	CardBody,
	SelectOption,
	ValueType,
	ImageType,
	DateFormats,
	FormLabel,
	DateInput,
} from 'carrier-fe';
import axios from 'axios';
import { InstallationFormData } from '../../pages/installation/journey';
import { useParams, useSearchParams } from 'react-router-dom';
import { STEPS, SYSTEM_TYPES } from '../../util/Constants';
import { format } from 'date-fns';

const Section = ({ children }: { children: React.ReactNode }) => {
	return <div className="mb-4">{children}</div>;
};

function Step3({ form, loading, stepLang }: StepProps) {
	const { store, setValues, errors, Input, setErrors } = form;
	const { crud, fields } = useLangContext();
	const { setDisabled, disabled } = useDisabledContext();
	const [isLoading, setIsLoading] = React.useState(false);
	const [showGasSafeFields, setShowGasSafeFields] = useState(
		form?.store?.system_type === SYSTEM_TYPES.GAS_BOILER
	);
	const [gasSafeResponses, setGasSafeResponses] = useState<{
		company: any;
		engineer: any;
	}>({
		company: null,
		engineer: null,
	});
	const [searchParams] = useSearchParams();
	const urlParams = useParams();
	const installationId =
		searchParams.get('installationId') || urlParams?.installationId;

	useLayoutEffect(() => {
		if (!store?.system_type || store?.system_type === '') {
			setIsLoading(true);
			setDisabled(true);
			getStepData(STEPS.STEP_2);
		}
	}, []);

	const getStepData = async (step: number) => {
		try {
			const response = await axios.get<{
				data: Partial<InstallationFormData>;
				message: string;
			}>(
				route(API_ENDPOINTS.REGISTRATION.INSTALLATION.EDIT, {
					installationId:
						installationId ||
						searchParams.get('installationId') ||
						'',
					step: String(step),
				})
			);

			setValues({ system_type: response?.data?.data?.system_type });
			setShowGasSafeFields(
				response?.data?.data?.system_type === SYSTEM_TYPES.GAS_BOILER
			);
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message,
				variant: Variants.Danger,
			});
		} finally {
			setIsLoading(false);
			setDisabled(false);
		}
	};

	const fetchGasSafeDetails = async (
		value: string | number,
		type: 'company' | 'engineer'
	) => {
		setErrors(null);
		setDisabled(true);

		const ENDPOINTS = {
			company: `${route(
				API_ENDPOINTS.GENERIC.GAS_SAFE.COMPANY.VIEW
			)}?gas_safe_number=${value}`,
			engineer: `${route(
				API_ENDPOINTS.GENERIC.GAS_SAFE.ENGINEER.VIEW
			)}?gas_safe_licence_card_number=${value}`,
		};

		const url = ENDPOINTS[type];

		try {
			const response = await axios.get(url);
			const gasSafe = { ...response.data.data };

			const companyValues: InstallationFormData = gasSafe && {
				subcontractor_address_line_1: gasSafe.address_line_1 ?? '',
				subcontractor_address_line_2: gasSafe.address_line_2 ?? '',
				subcontractor_address_line_3: gasSafe.address_line_3 ?? '',
				subcontractor_town_city: gasSafe.town_city ?? '',
				subcontractor_state_county: gasSafe.state_county ?? '',
				subcontractor_postcode_zipcode: gasSafe.postcode_zipcode ?? '',
				subcontractor_country_code_iso_3:
					gasSafe.country_code_iso_3 ?? '',
				subcontractor_company_name: gasSafe.name ?? '',
			};

			const engineerValues: InstallationFormData = gasSafe && {
				subcontractor_engineer_first_name: gasSafe.first_name ?? '',
				subcontractor_engineer_last_name: gasSafe.last_name ?? '',
			};

			if (type === 'company') {
				setValues(companyValues);
				setGasSafeResponses({ ...gasSafeResponses, company: gasSafe });
				customToast({
					title: response?.data?.message,
					variant: Variants.Success,
				});
			} else {
				setValues(engineerValues);
				setGasSafeResponses({ ...gasSafeResponses, engineer: gasSafe });
				customToast({
					title: response?.data?.message,
					variant: Variants.Success,
				});
			}
		} catch (error: any) {
			const message =
				error?.response?.data?.message || 'Error fetching details';
			customToast({ title: message, variant: Variants.Danger });

			setErrors((prevErrors: any) => ({
				...prevErrors,
				...error?.response?.data?.data?.errors,
			}));
		} finally {
			setDisabled(false);
		}
	};

	const yesNoOptions = useMemo(
		() =>
			crud?.options?.boolean?.map((option: SelectOption) => ({
				...option,
				value: Number(option.value) === 1 ? true : false,
			})),
		[crud?.options?.boolean]
	);

	const showNoneCompanyGasSafeFields = useMemo(
		() =>
			!showGasSafeFields
				? true
				: !!gasSafeResponses?.company ||
				  !!store?.subcontractor_company_name,
		[
			gasSafeResponses?.company,
			showGasSafeFields,
			store?.subcontractor_company_name,
		]
	);
	const showNoneEngineerGasSafeFields = useMemo(
		() =>
			!showGasSafeFields
				? true
				: !!gasSafeResponses?.engineer ||
				  !!store?.subcontractor_engineer_first_name,
		[
			gasSafeResponses?.engineer,
			showGasSafeFields,
			store?.subcontractor_engineer_first_name,
		]
	);

	const formatCompletionDate = (date: string) => {
		try {
			return date ? format(new Date(date), DateFormats.DB) : '';
		} catch (error) {
			return '';
		}
	};

	return (
		<Stepper.Step
			loading={loading || isLoading}
			title={stepLang?.title}
			
		>
			<Section>
				<SubTitle
					title={StringHelpers.title(
						crud?.sub_titles?.details || 'Details'
					)}
					className="mb-3 w-100"
					style={{ color: '#464C5E' }}
				/>
				<FormInput
					type="date"
					name="completion_date"
					label={StringHelpers.title(
						fields?.completion_date || 'Work Completion Date'
					)}
					value={formatCompletionDate(store?.completion_date)}
					onChange={(value) =>
						setValues({
							completion_date: value as string,
						})
					}
					errorMessages={errors?.completion_date}
					disabled={disabled}
				/>
				<FormInput
					type={'select'}
					name={'subcontractor_exists'}
					value={store?.subcontractor_exists}
					label={StringHelpers.title(
						fields?.subcontractor_exists || 'Work was subcontracted'
					)}
					onChange={(
						value: string | boolean | string[] | ImageType | null
					) =>
						setValues({
							subcontractor_exists:
								typeof value === 'string'
									? JSON.parse(value)
									: value,
						})
					}
					options={yesNoOptions}
					disabled={disabled}
				/>
			</Section>

			{store?.subcontractor_exists && (
				<Section>
					<SubTitle
						title={StringHelpers.title(
							stepLang?.sub_sections?.[0]?.title ||
								'Subcontractor'
						)}
						className="mb-3 w-100"
						style={{ color: '#464C5E', fontSize: 32 }}
					/>
					<p
						className="text-muted"
						style={{ fontSize: 14 }}
					>
						{stepLang?.sub_sections?.[0]?.description}
					</p>
					{showGasSafeFields && (
						<div className="d-flex align-items-start gap-2 justify-content-between">
							<div className="flex-fill">
								<FormInput
									noMargin
									type={'text'}
									value={
										store?.subcontractor_company_gas_safe_number ??
										''
									}
									name={
										'subcontractor_company_gas_safe_number'
									}
									label={StringHelpers.title(
										fields?.subcontractor_company_gas_safe_number ||
											'Company Gas Safe Number'
									)}
									onChange={(value) =>
										setValues({
											subcontractor_company_gas_safe_number:
												value as string,
											subcontractor_company_gas_safe_number_confirmed:
												false,
										})
									}
									errorMessages={
										errors?.gas_safe_number ||
										errors?.subcontractor_company_gas_safe_number
									}
									disabled={disabled}
								/>
							</div>
							<Button
								label="Search"
								variant={Variants.Dark}
								type="SOLID"
								size="Medium"
								onClick={() => {
									fetchGasSafeDetails(
										store?.subcontractor_company_gas_safe_number,
										'company'
									);
								}}
								disabled={disabled}
							/>
						</div>
					)}
					{Input({
						name: 'subcontractor_company_name',
						label: StringHelpers.title(
							fields?.subcontractor_company_name || 'Company Name'
						),
						type: 'text',
						visible: showNoneCompanyGasSafeFields,
					})}
					{showNoneCompanyGasSafeFields && (
						<PostcodeLookup
							data={{
								address_id:
									store?.subcontractor_address_id || '',
								sub_country_name:
									store?.subcontractor_sub_country_name || '',
								address_line_1:
									store?.subcontractor_address_line_1 || '',
								address_line_2:
									store?.subcontractor_address_line_2 || '',
								address_line_3:
									store?.subcontractor_address_line_3 || '',
								town_city: store?.subcontractor_town_city || '',
								state_county:
									store?.subcontractor_state_county || '',
								postcode_zipcode:
									store?.subcontractor_postcode_zipcode || '',
								local_authority_code:
									store?.subcontractor_local_authority_code ||
									'',
								country_code_iso_3:
									store?.subcontractor_country_code_iso_3 ||
									'',
							}}
							setData={(value) => {
								setValues({
									subcontractor_address_id: value?.address_id,
									subcontractor_address_line_1:
										value?.address_line_1,
									subcontractor_address_line_2:
										value?.address_line_2,
									subcontractor_address_line_3:
										value?.address_line_3,
									subcontractor_town_city: value?.town_city,
									subcontractor_state_county:
										value?.state_county,
									subcontractor_postcode_zipcode:
										value?.postcode_zipcode,
									subcontractor_country_code_iso_3:
										value?.country_code_iso_3,
									subcontractor_local_authority_code:
										value?.local_authority_code,
									subcontractor_sub_country_name:
										value?.sub_country_name,
								});
							}}
							errors={errors}
							hideLookup={
								gasSafeResponses?.company?.address_line_1
							}
							overrideDisabledFields={{
								country_code_iso_3: disabled || false,
								postcode_zipcode: disabled || false,
							}}
						/>
					)}
					{showGasSafeFields && (
						<FormInput
							type="select"
							name={
								'subcontractor_company_gas_safe_number_confirmed'
							}
							value={
								store?.subcontractor_company_gas_safe_number_confirmed
							}
							label={StringHelpers.sentence(
								fields?.subcontractor_company_gas_safe_number_confirmed ||
									"Can you confirm that the company's GasSafe details are correct?"
							)}
							onChange={(
								value:
									| string
									| boolean
									| string[]
									| ImageType
									| null
							) =>
								setValues({
									subcontractor_company_gas_safe_number_confirmed:
										typeof value === 'string'
											? JSON.parse(value)
											: value,
								})
							}
							errorMessages={
								errors?.subcontractor_company_gas_safe_number_confirmed
							}
							options={yesNoOptions}
							disabled={
								(!gasSafeResponses?.company &&
									!store?.subcontractor_company_name) ||
								disabled
							}
						/>
					)}
				</Section>
			)}
			{store?.subcontractor_exists &&
				(gasSafeResponses?.company ||
					store?.subcontractor_company_name) && (
					<Section>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.engineer || 'Engineer'
							)}
							className="mb-3 w-100"
							style={{ color: '#464C5E' }}
						/>
						{showGasSafeFields && (
							<div className="d-flex align-items-start gap-2 justify-content-between">
								<div className="flex-fill">
									<FormInput
										noMargin
										type={'text'}
										value={
											store?.subcontractor_engineer_gas_safe_licence_card_number ??
											''
										}
										name={
											'subcontractor_engineer_gas_safe_licence_card_number'
										}
										label={StringHelpers.title(
											fields?.gas_safe_licence_card_number ||
												'Engineer Gas Safe licence card Number'
										)}
										onChange={(value) =>
											setValues({
												subcontractor_engineer_gas_safe_licence_card_number:
													value as string,
												subcontractor_engineer_gas_safe_licence_card_number_confirmed:
													false,
											})
										}
										errorMessages={
											errors?.gas_safe_licence_card_number ||
											errors?.subcontractor_engineer_gas_safe_licence_card_number
										}
										disabled={disabled}
									/>
								</div>
								<Button
									label={StringHelpers.title(
										replaceKeyWithValue(
											crud?.placeholders?.search ||
												'Search :model',
											'model',
											''
										)
									)}
									variant={Variants.Dark}
									type="SOLID"
									size="Medium"
									onClick={() => {
										fetchGasSafeDetails(
											store?.subcontractor_engineer_gas_safe_licence_card_number,
											'engineer'
										);
									}}
									disabled={disabled}
								/>
							</div>
						)}
						<div className="row">
							<div className="col-12 col-md-6">
								{Input({
									name: 'subcontractor_engineer_first_name',
									label: StringHelpers.title(
										fields?.first_name || 'First Name'
									),
									type: 'text',
									visible: showNoneEngineerGasSafeFields,
								})}
							</div>
							<div className="col-12 col-md-6">
								{Input({
									name: 'subcontractor_engineer_last_name',
									label: StringHelpers.title(
										fields?.last_name || 'Last Name'
									),
									type: 'text',
									visible: showNoneEngineerGasSafeFields,
								})}
							</div>
						</div>
						{showGasSafeFields && (
							<FormInput
								type={'select'}
								name={
									'subcontractor_engineer_gas_safe_licence_card_number_confirmed'
								}
								value={
									store?.subcontractor_engineer_gas_safe_licence_card_number_confirmed
								}
								label={StringHelpers.sentence(
									fields?.subcontractor_engineer_gas_safe_licence_card_number_confirmed ||
										'Can you confirm that the engineer’s GasSafe details are correct?'
								)}
								onChange={(
									value:
										| string
										| boolean
										| string[]
										| ImageType
										| null
								) =>
									setValues({
										subcontractor_engineer_gas_safe_licence_card_number_confirmed:
											typeof value === 'string'
												? JSON.parse(value)
												: value,
									})
								}
								errorMessages={
									errors?.subcontractor_engineer_gas_safe_licence_card_number_confirmed
								}
								options={yesNoOptions}
								disabled={
									(!gasSafeResponses?.engineer &&
										!store?.subcontractor_engineer_first_name) ||
									disabled
								}
							/>
						)}
					</Section>
				)}
		</Stepper.Step>
	);
}

export default Step3;
